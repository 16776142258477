import { IsDevEnv, LocalStorage, navigation, React } from '@/config'
import { APIClient } from '@/services'
import { useIsomorphicEffect, useState } from '@codeleap/common'
import { ActivityIndicator, View } from '@/components'
import { useSSRTimer } from '@/utils'

import { createStyles } from '@codeleap/styles'
import { usePathname } from 'next/navigation'

export type AuthenticatedScreenProps<T extends AppRoute> = {
  children: React.ReactNode
  redirectOnFailure?: boolean
  redirectPath?: T
  redirectRouteParams?: Record<RouteParams<T>, string>
  showLoading?: boolean
  autoRedirect?: boolean
}

const READY_TIMEOUT = 10000
const FETCH_COUNT_LIMIT = 4
const FETCH_DELAY = 500
const DEBUG = IsDevEnv && false

export function AuthenticatedScreen<T extends AppRoute>(props: AuthenticatedScreenProps<T>) {
  const {
    children,
    redirectOnFailure = false,
    redirectPath = 'Home',
    redirectRouteParams,
    autoRedirect = false,
  } = props

  const { isLoggedIn, loginResolved, profileQuery, hasFirebaseUser, logout, profile } = APIClient.Session.useSession()

  const [fetchCounter, setFetchCounter] = useState(0)
  const [ready, setReady] = useState(false)

  

  const invalidateSession = async () => {
    if (DEBUG) {
      logger.log('invalidateSession', {
        fetchCounter,
        ready,
        isLoggedIn,
        hasFirebaseUser,
        hasTimer: readyTimer.hasTimer,
      })
    }

    logout(false).then(() => {
      setReady(true)
      setFetchCounter(0)
    })
  }

  const readyTimer = useSSRTimer(ready, invalidateSession, READY_TIMEOUT)

  const invalidateSessionVerification = () => {
    if (readyTimer.hasTimer || fetchCounter !== 0) {
      if (DEBUG) {
        logger.log('invalidateSessionVerification', { fetchCounter, ready, hasTimer: readyTimer.hasTimer })
      }

      setFetchCounter(0)
      readyTimer.cancel()
    }
  }

  const onRedirect = () => {
    if (loginResolved && !isLoggedIn && redirectOnFailure) {
      navigation.navigate(redirectPath as T, redirectRouteParams)
    }
  }

  const rehydrateSession = async () => {
    await profileQuery.refetch()

    onRedirect()
  }

  useIsomorphicEffect(() => {
    rehydrateSession()

    const isAuthReady = LocalStorage.getItem('PERSIST_AUTH', false)

    if (!isAuthReady && !isLoggedIn && autoRedirect) {
      navigation.navigate(redirectPath as T, redirectRouteParams)
      return
    }

    if (isAuthReady === 'true' && !!isLoggedIn) {
      setReady(true)
    } else if (!isAuthReady && !isLoggedIn) {
      setReady(true)
    }
  }, [isLoggedIn, hasFirebaseUser])

  const verifySession = async () => {
    if (profile?.id) return

    const _fetch = await profileQuery.refetch()

    if (DEBUG) {
      logger.log('verifySession', { data: _fetch?.data, fetchCounter, ready })
    }

    if (!_fetch?.data?.id) {
      setFetchCounter(counter => counter + 1)
    }
  }

  const pathname = usePathname()

  useIsomorphicEffect(() => {
    if (ready) {
      invalidateSessionVerification()
      return
    }

    if (fetchCounter > FETCH_COUNT_LIMIT) {
      readyTimer.trigger()
    } else {
      setTimeout(() => {
        verifySession()
      }, FETCH_DELAY)
    }
  }, [fetchCounter, ready])

  useIsomorphicEffect(() => {
    invalidateSessionVerification()
  }, [pathname])

  if (!ready) {
    return (
      <View style={styles.wrapper}>
        <ActivityIndicator debugName='AuthenticatedScreen:loading' />
      </View>
    )
  }

  return <>
    {children}
  </>
}

const styles = createStyles(theme => ({
  wrapper: {
    width: '100vw',
    height: '100svh',
    ...theme.presets.absolute,
    ...theme.presets.whole,
    ...theme.presets.center,
    zIndex: 5,
  },
}))
