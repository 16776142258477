import { ButtonComposition, CollapseComposition } from '@codeleap/web'
import { customTextStyles } from './Text'
import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'

export type NavigationComposition =
  'section' |
  `collapse${Capitalize<CollapseComposition>}` |
  `title${Capitalize<ButtonComposition>}` |
  `item${Capitalize<ButtonComposition>}` |
  'titleWrapper:collapsible' |
  'titleText:collapsible' |
  `collapseWrapper:collapsible` |
  'itemWrapper:focused' |
  'itemText:focused' |
  'itemIcon:focused' |
  'group'

const createNavigationVariant = createStyles<NavigationComposition>

export const NavigationStyles = {
  default: createNavigationVariant((theme) => ({
    section: {
      ...theme.presets.column,
    },
    'collapseWrapper': {
      ...theme.presets.column,
      ...theme.presets.fullWidth,
      ...theme.effects.light,
    },
    'titleWrapper': {
      backgroundColor: 'transparent',
      cursor: 'default',
      transition: 'all .2s ease',
      ...theme.spacing.marginBottom(1),
      '&:hover': {
        backgroundColor: theme.colors.neutral1,
      },
    },
    'titleWrapper:collapsible': {
      cursor: 'pointer',

      '&:hover': {
        background: theme.colors.neutral2,
      },
    },
    'titleIcon': {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    titleText: {
      ...customTextStyles('p3'),
    },
    'itemWrapper': {
      backgroundColor: theme.colors.neutral1,
      ...theme.presets.fullWidth,
      ...theme.presets.justifyStart,
      textDecoration: 'none',
      borderRadius: 0,
      ...theme.spacing.padding(2.5),

      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },

      '&:last-of-type': {
        borderBottomWidth: 0,
      },
    },

  })),
  list: createNavigationVariant(theme => ({
    'section': {
      alignItems: 'flex-start',

      [theme.media.up('tabletSmall')]: {
        padding: theme.spacing.value(0),
        margin: theme.spacing.value(0),
      },

      [theme.media.down('tabletSmall')]: {
        ...theme.presets.fullWidth,
        flex: 1,
      },
    },
    'collapseWrapper': {
      borderRadius: theme.borderRadius.small,

      [theme.media.down('tabletSmall')]: {
        ...theme.presets.fullWidth,
        ...theme.presets.row,
      },
    },
    'group': {
      borderRadius: theme.borderRadius.small,
      overflow: 'hidden',
      ...theme.presets.column,

      [theme.media.down('tabletSmall')]: {
        ...theme.presets.fullWidth,
        ...theme.presets.row,

      },
    },
    'collapseWrapper:collapsible': {
      [theme.media.down('tabletSmall')]: {
        ...theme.presets.fullWidth,
        ...theme.presets.column,
      },
    },
    'titleText': {
      ...customTextStyles('p2'),
    },
    'itemWrapper': {
      backgroundColor: theme.colors.neutral2,
      ...theme.presets.center,
      borderRadius: 0,
      ...theme.spacing.padding(2.5),
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: theme.colors.neutral3,
      },

      [theme.media.down('tabletSmall')]: {
        flex: 1,
        borderWidth: 0,
      },
    },
    'itemWrapper:focused': {
      background: theme.colors.neutral3,

      '&:hover': {
        backgroundColor: theme.colors.neutral4,
      },
    },
  })),
  'lastItem:collapsible': createNavigationVariant(theme => ({
    'itemWrapper': {
      borderBottomRightRadius: theme.borderRadius.small,
      borderBottomLeftRadius: theme.borderRadius.small,
    },
  })),
  menu: createNavigationVariant(theme => ({
    'section': {
      ...theme.presets.alignStart,
      ...theme.presets.justifySpaceBetween,

      [theme.media.up('tabletSmall')]: {
        borderRadius: theme.borderRadius.small,
        padding: theme.spacing.value(0),
        margin: theme.spacing.value(0),
      },

      [theme.media.down('tabletSmall')]: {
        ...theme.presets.fullWidth,
        flex: 1,
      },
    },
    'collapseWrapper': {
      borderRadius: theme.borderRadius.small,

      [theme.media.down('tabletSmall')]: {
        ...theme.presets.fullWidth,
        ...theme.presets.row,
        ...theme.border({ color: theme.colors.neutral2, 'directions': ['bottom'], 'width': 1 }),
      },
    },
    'collapseWrapper:collapsible': {
      [theme.media.up('tabletSmall')]: {
        ...theme.effects.light,
        borderRadius: theme.borderRadius.small,
      },

      [theme.media.down('tabletSmall')]: {
        ...theme.effects.light,
        ...theme.presets.column,
      },
    },
    'group': {
      borderRadius: theme.borderRadius.small,

      overflow: 'hidden',
      ...theme.presets.column,

      [theme.media.up('tabletSmall')]: {
        ...theme.effects.light,
      },

      [theme.media.down('tabletSmall')]: {
        ...theme.presets.fullWidth,
        borderRadius: 0,
        ...theme.presets.row,
        ...theme.border({ color: theme.colors.neutral2, 'directions': ['bottom'], 'width': 1 }),
      },
    },
    'titleWrapper': {
      ...theme.spacing.marginTop(2),
      ...theme.presets.fullWidth,
    },
    'titleText': {
      ...customTextStyles('p3'),
    },
    'titleText:collapsible': {
      textAlign: 'start',
    },
    'itemWrapper': {
      backgroundColor: theme.colors.neutral1,
      borderRadius: 0,
      ...theme.border({ color: theme.colors.primary3, 'directions': ['bottom'], 'width': 0 }),
      ...theme.spacing.padding(2.5),
      textDecoration: 'none',
      ...theme.spacing.gap(2),

      '&:hover': {
        backgroundColor: theme.colors.neutral1,
        filter: 'brightness(95%)',
      },

      [theme.media.down('tabletSmall')]: {
        flex: 1,
      },
    },
    'itemWrapper:focused': {
      [theme.media.down('tabletSmall')]: {
        flex: 1,
      },
    },
    'itemText': {
      ...theme.presets.fullWidth,
      textAlign: 'start',
    },
    'itemText:focused': {
      color: theme.colors.primary3,
    },
    'itemIcon': {
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
    'itemIcon:focused': {
      color: theme.colors.primary3,
    },
  })),
}

StyleRegistry.registerVariants('Navigation', NavigationStyles)
