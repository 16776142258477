import { AppImages, StyleSheets, React, Settings, StyleRegistry } from '@/config'
import { View, CenterWrapper, Footer, Header, Image, ImageProps, HeaderProps, FooterProps, CenterWrapperProps } from '@/components'
import { StyledComponent, StyledProp, useCompositionStyles } from '@codeleap/styles'
import { useUpdateLocale } from '@/utils'
import { useStylesFor } from '@codeleap/web'
import Head from 'next/head'

export type PageProps = {
  showHeader?: boolean
  showFooter?: boolean
  showBackgroundImage?: boolean
  backgroundImageSource?: string
  backgroundImageProps?: ImageProps
  pageTitle?: string
  appendNameToPageTitle?: boolean
  centerContent?: boolean
  className?: string
  contentProps?: CenterWrapperProps
  footerProps?: FooterProps
  headerProps?: HeaderProps
  style?: StyledProp<StyleSheets.PageComposition>
  children: React.ReactNode
}

export const Page: StyledComponent<typeof StyleSheets.PageStyles, PageProps> = (props) => {
  const {
    showHeader = true,
    showFooter = true,
    pageTitle,
    appendNameToPageTitle = true,
    centerContent = true,
    showBackgroundImage = false,
    backgroundImageSource = AppImages.LogoImage,
    backgroundImageProps,
    className,
    children,
    style,
    contentProps,
    headerProps,
    footerProps,
    ...rest
  } = props

  const styles = useStylesFor(Page.styleRegistryName, style)

  const compositionStyles = useCompositionStyles(['content', 'footer', 'header'], styles)

  useUpdateLocale()

  const appendedTitle = appendNameToPageTitle ? ` | ${Settings.AppName}` : ''
  const headTitle = pageTitle ? `${pageTitle} ${appendedTitle}` : Settings.AppName

  const innerWrapperStyles = React.useMemo(() => ([
    styles.innerWrapper,
    styles['innerWrapper:backgroundImage'],
  ]), [])

  const InnerWrapper = centerContent ? CenterWrapper : React.Fragment

  const innerProps = centerContent ? { ...contentProps, style: compositionStyles.content } : {}

  return <>
    <Head><title>{headTitle}</title></Head>

    <View className={className} {...rest} style={styles.wrapper}>
      {showBackgroundImage ? (
        <Image
          source={backgroundImageSource}
          alt='Page Image'
          {...backgroundImageProps}
          style={styles.backgroundImage}
        />
      ) : null}

      <View style={innerWrapperStyles}>
        {showHeader ? <Header {...headerProps} style={compositionStyles.header} /> : null}
        <InnerWrapper {...innerProps}>
          {children}
        </InnerWrapper>
        {showFooter ? <Footer {...footerProps} style={compositionStyles.footer} /> : null}
      </View>
    </View>
  </>
}

Page.styleRegistryName = 'Page'

Page.elements = [
  'wrapper',
  'innerWrapper',
  'backgroundImage',
  `content`,
  `footer`,
  `header`,
]

StyleRegistry.registerComponent(Page)
