import { createStyles } from '@codeleap/styles'
import { customTextStyles } from './Text'
import { ProgressCircleComposition } from '@codeleap/web'
import { StyleRegistry } from '../styles'

const createProgressCircleVariant = createStyles<ProgressCircleComposition>

export const ProgressCircleStyles = {
  default: createProgressCircleVariant((theme) => ({
    wrapper: {},
    circle: {
      size: 160,
    },
    line: {
      borderColor: theme.colors.primary3,
      backgroundColor: theme.colors.neutral3,
    },
    image: {
      height: theme.values.iconSize[4],
      width: theme.values.iconSize[4],
    },
    innerWrapper: {
      ...theme.presets.column,
      ...theme.presets.alignCenter,
    },
    text: {
      ...customTextStyles('h2'),
      color: theme.colors.neutral7,
    },
    icon: {
      size: theme.values.iconSize[4],
      color: theme.colors.neutral7,
    },
  })),
}

StyleRegistry.registerVariants('ProgressCircle', ProgressCircleStyles)
