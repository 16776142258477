"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCompositionStyles = exports.useTheme = exports.useNestedStylesByKey = exports.useStyleObserver = void 0;
var react_1 = require("react");
var utils_1 = require("./utils");
var shallow_1 = require("zustand/react/shallow");
var themeStore_1 = require("./themeStore");
var useStyleObserver = function (style) {
    return (0, react_1.useMemo)(function () {
        if (Array.isArray(style)) {
            return JSON.stringify(style === null || style === void 0 ? void 0 : style.filter(function (v) { return !!v; }));
        }
        else if (typeof style === 'object') {
            return JSON.stringify(style);
        }
        else {
            return style;
        }
    }, [style]);
};
exports.useStyleObserver = useStyleObserver;
function useNestedStylesByKey(match, componentStyles) {
    var styles = __assign({}, componentStyles);
    return (0, react_1.useMemo)(function () {
        return (0, utils_1.getNestedStylesByKey)(match, styles);
    }, [styles]);
}
exports.useNestedStylesByKey = useNestedStylesByKey;
var useTheme = function (selector) {
    return (0, themeStore_1.themeStore)((0, shallow_1.useShallow)(selector));
};
exports.useTheme = useTheme;
function useCompositionStyles(composition, componentStyles) {
    var styles = __assign({}, componentStyles);
    return (0, react_1.useMemo)(function () {
        var e_1, _a;
        var compositionStyles = {};
        try {
            for (var composition_1 = __values(composition), composition_1_1 = composition_1.next(); !composition_1_1.done; composition_1_1 = composition_1.next()) {
                var element = composition_1_1.value;
                compositionStyles[element] = (0, utils_1.getNestedStylesByKey)(element, styles);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (composition_1_1 && !composition_1_1.done && (_a = composition_1.return)) _a.call(composition_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return compositionStyles;
    }, [styles]);
}
exports.useCompositionStyles = useCompositionStyles;
