"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.multiplierProperty = void 0;
function multiplierProperty(base, property) {
    return function (multiplier) {
        var _a;
        var value = base * Number(multiplier);
        return _a = {},
            _a[property] = value,
            _a;
    };
}
exports.multiplierProperty = multiplierProperty;
