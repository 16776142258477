import { ModalComposition } from '@codeleap/web'
import { customTextStyles } from './Text'
import { StyleRegistry } from '../styles'
import { createStyles } from '@codeleap/styles'

const createModalVariant = createStyles<ModalComposition>

export const ModalStyles = {
  default: createModalVariant((theme) => ({
    wrapper: {
      width: '100vw',
      minHeight: '100svh',
      position: 'fixed',
      ...theme.presets.whole,
      zIndex: 999,
      transition: 'visibility 0.2s ease',
      animation: 'fadeIn 0.2s ease-in-out',
    },
    'wrapper:hidden': {
      visibility: 'hidden',
      pointerEvents: 'none',
    },
    'wrapper:visible': {
      visibility: 'visible',
      pointerEvents: 'auto',
    },
    innerWrapper: {
      ...theme.presets.justifyCenter,
      ...theme.presets.alignStart,
      ...theme.presets.scrollY,
      minWidth: '100vw',
      width: '100vw',
    },
    backdropPressable: {
      ...theme.presets.whole,
      ...theme.presets.absolute,
      zIndex: 1,
      cursor: 'default',
    },
    backdrop: {
      ...theme.presets.absolute,
      ...theme.presets.whole,
      backgroundColor: theme.colors.neutral10,
      zIndex: -1,
      minHeight: '100svh',
      transition: 'opacity 0.2s ease-in-out',
      animation: 'opacity 0.2s ease-in-out',
    },
    'backdrop:visible': {
      opacity: 0.5,
    },
    'backdrop:hidden': {
      opacity: 0,
    },
    box: {
      backgroundColor: theme.colors.neutral1,
      maxWidth: `85dvw`,
      borderRadius: theme.borderRadius.medium,
      ...theme.presets.column,
      zIndex: 2,
      ...theme.spacing.padding(4),
      ...theme.presets.relative,
      overflow: 'hidden',
      transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
      animation: 'scaleIn 0.2s ease-in-out',

      [theme.media.down('mobile')]: {
        ...theme.spacing.padding(2),
      },
    },
    'box:hidden': {
      opacity: 0,
      transform: `scale(0.8)`,
    },
    'box:visible': {
      transform: `scale(1)`,
    },
    body: {
      ...theme.presets.column,
    },
    header: {
      ...theme.presets.column,
      ...theme.spacing.marginBottom(2),
      ...theme.presets.alignStart,
      ...theme.presets.justifySpaceBetween,
      backgroundColor: theme.colors.neutral1,
    },
    titleWrapper: {
      ...theme.presets.relative,
      ...theme.presets.fullWidth,
      ...theme.presets.row,
      ...theme.presets.center,
    },
    title: {
      ...customTextStyles('h4'),
      color: theme.colors.neutral10,
    },
    description: {
      ...theme.presets.fullWidth,
      textAlign: 'center',
      color: theme.colors.neutral8,
      ...theme.spacing.marginTop(1),
      ...customTextStyles('p1'),
    },
    footer: {
      ...theme.spacing.marginTop(2),
    },
    'closeButtonTouchableWrapper': {
      padding: theme.spacing.value(0),
      width: 'auto',
      height: 'auto',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      borderRadius: 0,
      background: theme.colors.transparent,
    },
    closeButtonIcon: {
      color: theme.colors.primary3,
      width: theme.values.iconSize[3],
      height: theme.values.iconSize[3],
    },
  })),
  fullscreen: createModalVariant((theme) => ({
    innerWrapper: {
      padding: 0,
      backgroundColor: theme.colors.neutral1,
      minHeight: '100lvh',
    },
    box: {
      maxWidth: '100vw',
      width: '100vw',
      minHeight: '100svh',
      borderRadius: 0,
    },
    body: {
      maxWidth: '100vw',
      width: '100vw',
    },
  })),
  centered: createModalVariant((theme) => ({
    innerWrapper: {
      ...theme.presets.center,
    },
    title: {
      margin: 'auto',
    },
  })),
  'overflow:visible': createModalVariant((theme) => ({
    box: {
      overflow: 'visible',
    },
    body: {
      overflow: 'visible',
    },
  })),
  'box:responsiveness': createModalVariant((theme) => {
    const MAX_BOX_WIDTH = 450

    return {
      box: {
        width: `calc(100vw - ${theme.spacing.value(4)}px)`,
        maxWidth: MAX_BOX_WIDTH,
      },
    }
  }),
}

StyleRegistry.registerVariants('Modal', ModalStyles)
