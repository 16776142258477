import React from 'react'
import { TypeGuards, GlobalAlertType, OSAlertStore, AnyFunction } from '@codeleap/common'
import { useNestedStylesByKey, StyledComponent, ICSS } from '@codeleap/styles'
import { Modal, Text, View, Button } from '@/components'
import { appStatusStore } from '@/utils'
import { ActionButtonProps, AlertOptionProps, AlertProps, AlertType, DismissButtonProps } from './types'
import { StyleSheets, StyleRegistry } from '@/config'
import { useStylesFor } from '@codeleap/web'

export * from './types'

function verifyType(type: AlertType) {
  return {
    isInfo: type === 'info',
    isWarn: type === 'warn',
    isError: type === 'error',
    isDestructive: type === 'destructive',
  }
}

function ActionButton({ onAction, actionButtonProps, isDestructive }: ActionButtonProps & { isDestructive: boolean }) {
  const initialText = isDestructive ? 'Delete' : 'OK'
  return (
    <Button
      text={initialText}
      debugName={`${initialText} button from alert modal`}
      {...actionButtonProps}
      style={['flex', isDestructive && 'destructive']}
      onPress={onAction}
    />
  )
}

function DismissButton({ onDismiss, dismissButtonProps }: DismissButtonProps) {
  return (
    <Button
      text='Dismiss'
      debugName='Dismiss button from alert modal'
      {...dismissButtonProps}
      style={['outline', 'flex']}
      onPress={onDismiss}
    />
  )
}

export const Alert: StyledComponent<typeof StyleSheets.AlertStyles, AlertProps> = (props) => {
  const {
    body,
    children,
    options,
    orientation = 'row',
    closeOnDismiss = true,
    closeOnAction = true,
    autoClose = true,
    type,
    onAction,
    actionButtonProps,
    dismissButtonProps,
    onDismiss,
    optionsOnly,
    style,
    ...modalProps
  } = props

  const styles = useStylesFor(Alert.styleRegistryName, style)

  const { isWarn, isDestructive } = React.useMemo(() => verifyType(type), [type])

  const appStatus = appStatusStore(store => store.status)

  const modalStyles = useNestedStylesByKey('modal', styles)

  const isRow = orientation === 'row'

  const showActionButton = isWarn || isDestructive

  const hasOptions = options?.length > 0 && !!options

  const handleOption = (cb?: AnyFunction) => {
    if (TypeGuards.isFunction(cb)) cb?.()
    if (autoClose) modalProps?.toggle?.(false)
  }

  const handleDismiss = () => {
    if (TypeGuards.isFunction(onDismiss)) onDismiss?.()
    if (closeOnDismiss) modalProps?.toggle?.(false)
  }

  const handleAction = () => {
    if (TypeGuards.isFunction(onAction)) onAction?.()
    if (closeOnAction) modalProps?.toggle?.(false)
  }

  const ActionComponent = React.useCallback(() => (
    <View style={['flex']}>
      <ActionButton onAction={handleAction} actionButtonProps={actionButtonProps} isDestructive={isDestructive} />
    </View>
  ), [handleAction])

  const RenderOption = React.useCallback((optionProps: AlertOptionProps) => {
    return (
      <Button
        {...optionProps}
        onPress={() => handleOption(optionProps?.onPress)}
        style={[styles.option, optionProps?.style as ICSS]}
      />
    )
  }, [])

  return (
    <Modal
      showClose={false}
      closable={false}
      {...modalProps}
      style={[modalStyles, 'centered', type]}
      visible={modalProps?.visible && appStatus !== 'done' && appStatus !== 'loading'}
    >
      {body ? <Text text={body} style={styles.body} /> : null}

      {children}

      <View style={[orientation, styles.actions]}>
        {(showActionButton && !isRow && !optionsOnly) ? <ActionComponent /> : null}

        {!!optionsOnly ? null : (
          <View style={['flex']}>
            <DismissButton onDismiss={handleDismiss} dismissButtonProps={dismissButtonProps} />
          </View>
        )}

        {hasOptions && options?.map((optionProps, i) => (
          <RenderOption {...optionProps} key={optionProps?.text + 'alert' + i} />),
        )}

        {(showActionButton && isRow && !optionsOnly) ? <ActionComponent /> : null}
      </View>
    </Modal>
  )
}

Alert.styleRegistryName = 'Alert'
Alert.elements = ['body', 'actions', 'option', 'modal']

StyleRegistry.registerComponent(Alert)

const typeCast: Record<Exclude<GlobalAlertType, 'custom'>, AlertType> = {
  ask: 'destructive',
  info: 'info',
  warn: 'warn',
  error: 'error',
}

const GlobalAlertComponent = () => {
  const { visible, toggle, props } = OSAlertStore(store => store)

  const { title, body, onDismiss, onAction, options, type: customType, ...alertProps } = props

  const type = typeCast[customType]

  const alertOptions = options?.map(o => ({ debugName: o?.text, ...o }))

  return (
    <Alert
      debugName='Global alert'
      title={title}
      body={body}
      onAction={onAction}
      onDismiss={onDismiss}
      options={alertOptions}
      optionsOnly={!onAction && !onDismiss && !!options?.length}
      type={type || customType}
      {...alertProps}
      toggle={toggle}
      visible={visible}
    />
  )
}

export const GlobalAlert = React.memo(GlobalAlertComponent, () => true)
