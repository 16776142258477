import { theme, React } from '@/config'
import { Text, View, Page, Navigator, SearchInput, PageProps } from '@/components'
import { useState } from '@codeleap/common'
import { isMediaQuery } from '@codeleap/web'
import { useIsTablet, useUpdateLocale } from '@/utils'

export type PageExampleProps = Pick<PageProps, 'centerContent' | 'showHeader' | 'showFooter' | 'showBackgroundImage'>

const preparePages = (pages: string[]) => pages.sort((a, b) => a[0].localeCompare(b[0]))

const NavigationPages = preparePages([
  'Page',
  'Modal',
  'Overlay',
  'Alert',
  'Pager',
  'Navigation',
])

const AccessoriesPages = preparePages([
  'View',
  'Icons',
  'List',
  'Grid',
  'Image',
  'Avatar',
  'AppStatus',
  'ActivityIndicator',
  'SegmentedControl',
  'Card',
  'Tooltip',
  'Text',
  'Progress',
  'Tag',
])

const ButtonsPages = preparePages([
  'Touchable',
  'Button',
  'ActionIcon',
  'PaginationButtons',
  'SectionFilters',
])

const InputsPages = preparePages([
  'TextInput',
  'FileInput',
  'Select',
  'Dropzone',
  'Checkbox',
  'RadioInput',
  'Switch',
  'Slider',
  'NumberIncrement',
  'TextInputMask',
  'SearchInput',
  'LanguageSelector',
  'DatePicker',
  'CropPicker',
  'TextEditor',
  'PhoneInput',
])

const UtilsPages = preparePages([
  'PerformanceInspector',
  'Debug',
])

const ExamplePages = [
  {
    title: 'Navigation',
    data: NavigationPages,
  },
  {
    title: 'Accessories',
    data: AccessoriesPages,
  },
  {
    title: 'Buttons',
    data: ButtonsPages,
  },
  {
    title: 'Inputs',
    data: InputsPages,
  },
  {
    title: 'Utils',
    data: UtilsPages,
  },
]

type ComponentExampleLayoutProps = React.PropsWithChildren & PageExampleProps

export const ComponentExampleLayout = ({ children, ...pageProps }: ComponentExampleLayoutProps) => {
  const [search, setSearch] = useState('')

  const isTablet = useIsTablet()

  useUpdateLocale()

  const onChangeSearch = async (searchValue: string) => {
    setSearch(searchValue)
  }

  return <Page pageTitle='Component Examples' showFooter={false} centerContent={false} {...pageProps}>
    <View style={[isTablet ? 'column' : 'row', 'fullWidth', 'marginTop:2', !isTablet && 'padding:2']}>
      <View
        style={['column', 'padding:1', 'marginRight:2', { maxWidth: isTablet ? '100vw' : '25vw' }]}
      >
        <Text text='Components' style={['h2']} />

        <SearchInput
          placeholder='Search'
          debugName={'Search Input components example'}
          onSearchChange={onChangeSearch}
          style={['marginVertical:2', 'noError']}
        />

        <View style={['column', 'flex', 'gap:2']}>
          <Navigator.Container style={['menu']}>
            {ExamplePages.map(({ title, data }, i) => (
              <Navigator.Section
                key={i + title}
                debugName='Navigator Section'
                title={title}
                collapsible
                initiallyCollapsed={isMediaQuery(theme.media.down('tabletSmall'))}
              >
                {data.map(name => {
                  if (!!search && !name.toLowerCase().includes(search.toLowerCase())) return null
                  return (
                    <Navigator.Button
                      key={i + name}
                      to='Components.View'
                      routeParams={{ component: name }}
                      text={name}
                    />
                  )
                })}
              </Navigator.Section>
            ))}
          </Navigator.Container>
        </View>
      </View>

      {children}
    </View>
  </Page>
}
