"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyleCache = void 0;
var Cacher_1 = require("./Cacher");
var hashKey_1 = require("./hashKey");
var constants_1 = require("./constants");
var minifier_1 = require("./minifier");
var StyleCache = /** @class */ (function () {
    function StyleCache(storage) {
        this.styles = new Cacher_1.Cache('styles');
        this.compositions = new Cacher_1.Cache('compositions');
        this.responsive = new Cacher_1.Cache('responsive');
        this.variants = new Cacher_1.Cache('variants', storage);
        this.common = new Cacher_1.Cache('common', storage);
        this.components = new Cacher_1.Cache('components', storage);
    }
    StyleCache.prototype.registerBaseKey = function (keys) {
        keys.push(constants_1.StyleConstants.STORES_PERSIST_VERSION);
        var baseKey = (0, hashKey_1.hashKey)(keys);
        this.baseKey = baseKey;
        return baseKey;
    };
    StyleCache.prototype.wipeCache = function () {
        this.components.clear();
        this.responsive.clear();
        this.compositions.clear();
        this.variants.clear();
        this.common.clear();
        this.styles.clear();
    };
    StyleCache.prototype.keyFor = function (type, keyData) {
        var _a;
        var cache = this[type];
        var values = [this.baseKey, keyData];
        var cacheKey = (0, hashKey_1.hashKey)(values);
        var cachedValue = minifier_1.minifier.decompress((_a = cache.cache[cacheKey]) !== null && _a !== void 0 ? _a : null);
        return {
            key: cacheKey,
            value: cachedValue,
        };
    };
    StyleCache.prototype.cacheFor = function (type, key, value) {
        if (!constants_1.StyleConstants.CACHE_ENABLED) {
            return value;
        }
        var cache = this[type];
        var minifiedValue = minifier_1.minifier.compress(value);
        return cache.cacheFor(key, minifiedValue);
    };
    return StyleCache;
}());
exports.StyleCache = StyleCache;
