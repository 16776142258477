import { theme, Settings, StyleSheets } from '@/config'
import { View, Link, Icon } from '@/components'
import { StylesOf } from '@codeleap/common'
import { AppIcon } from '@codeleap/styles'

export type FooterSocialMediaProps = {
  styles?: StylesOf<StyleSheets.FooterComposition>
}

const links = [
  {
    url: Settings.Social.FacebookURL,
    icon: 'facebook',
    size: theme.values.iconSize[3],
    color: theme.colors.neutral10,
  },
  {
    url: Settings.Social.LinkedinURL,
    icon: 'linkedin',
    size: theme.values.iconSize[3],
    color: theme.colors.neutral10,
  },
]

export const FooterSocialMedia = (props: FooterSocialMediaProps) => {
  const { styles } = props

  return (
    <View style={styles.socialMediaWrapper}>
      {links.map((media, i) => (
        <Link key={i + media?.icon} to={media?.url}>
          <Icon
            name={media?.icon as AppIcon}
            color={media?.color}
            size={media?.size}
            debugName={media?.icon + 'footer-icon'}
          />
        </Link>
      ))}
    </View>
  )
}
