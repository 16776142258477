"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMediaQueries = void 0;
function getBreakpointValue(breakpoint, breakpoints) {
    if (breakpoints[breakpoint]) {
        return breakpoints[breakpoint];
    }
    return Infinity;
}
function createMediaQueries(breakpoints) {
    function getBreakpoint(breakpoint) {
        return getBreakpointValue(breakpoint, breakpoints);
    }
    var queries = {
        up: function (breakpoint) {
            // Upwards of... (excluding)
            var min = getBreakpoint(breakpoint);
            return "@media screen and (min-width:".concat(min, "px)");
        },
        down: function (breakpoint) {
            // Downwards of... (excluding)
            var max = getBreakpoint(breakpoint);
            return "@media screen and (max-width:".concat(max, "px)");
        },
        is: function (breakpoint) {
            // Is media... (exact)
            var value = getBreakpoint(breakpoint);
            return "@media screen and (min-width:".concat(value, "px) and (max-width:").concat(value, "px)");
        },
        not: function (breakpoint) {
            // Is NOT media... (exact)
            var value = getBreakpoint(breakpoint);
            return "@media not screen and (min-width:".concat(value, "px) and (max-width:").concat(value, "px)");
        },
    };
    var renderToPlatformQuery = function (props) {
        var query = '';
        if (props === null || props === void 0 ? void 0 : props.is) {
            query = queries.not(props.is);
        }
        else if (props === null || props === void 0 ? void 0 : props.not) {
            query = queries.is(props.not);
        }
        else if (props === null || props === void 0 ? void 0 : props.up) {
            query = queries.down(props.up);
        }
        else if (props === null || props === void 0 ? void 0 : props.down) {
            query = queries.up(props.down);
        }
        return query;
    };
    return __assign(__assign({}, queries), { renderToPlatformQuery: renderToPlatformQuery });
}
exports.createMediaQueries = createMediaQueries;
