import { createStyles } from '@codeleap/styles'
import { customTextStyles } from './Text'
import { RadioInputComposition } from '@codeleap/web'
import { StyleRegistry } from '../styles'

const createRadioInputVariant = createStyles<RadioInputComposition>

export const RadioInputStyles = {
  default: createRadioInputVariant((theme) => {
    const indicatorSize = theme.values.itemHeight.tiny
    const ellipsisSize = indicatorSize * 0.5
    return {
      wrapper: {
        ...theme.presets.column,
      },
      optionIndicator: {
        ...theme.border({ color: theme.colors.neutral5, width: 1 }),
        borderRadius: theme.borderRadius.rounded,
        height: indicatorSize,
        width: indicatorSize,
        ...theme.presets.center,
        cursor: 'pointer',
      },
      'optionIndicator:disabled': {
        ...theme.border({ color: theme.colors.neutral3, width: 1 }),
        cursor: 'not-allowed',
      },
      'optionIndicator:selected': {
        ...theme.border({ color: theme.colors.primary3, width: 1 }),
        backgroundColor: theme.colors.primary3,
      },
      'optionIndicator:selectedDisabled': {
        ...theme.border({ color: theme.colors.primary1, width: 1 }),
        backgroundColor: theme.colors.primary1,
      },
      optionIndicatorInner: {
        height: ellipsisSize,
        width: ellipsisSize,
        backgroundColor: theme.colors.neutral1,
        borderRadius: theme.borderRadius.rounded,
      },
      label: {
        ...customTextStyles('p2'),
        color: theme.colors.neutral7,
        ...theme.spacing.marginBottom(1),
        cursor: 'pointer',
      },
      description: {
        ...customTextStyles('p4'),
        color: theme.colors.neutral7,
        ...theme.spacing.marginBottom(1),
        ...theme.spacing.marginTop(0),
      },
      'label:disabled': {
        cursor: 'not-allowed',
      },
      optionWrapper: {
        ...theme.spacing.marginTop(1),
        ...theme.spacing.marginRight(1),
        ...theme.presets.row,
        ...theme.presets.alignCenter,
      },
      optionSeparator: {
        ...theme.spacing.marginBottom(2),
      },
      optionLabel: {
        ...customTextStyles('p1'),
        color: theme.colors.neutral8,
        ...theme.spacing.marginLeft(1),
      },
      'optionLabel:disabled': {
        color: theme.colors.neutral5,
      },
      'optionLabel:selectedDisabled': {
        color: theme.colors.neutral5,
      },
      errorMessage: {
        ...customTextStyles('p4'),
        color: theme.colors.destructive2,
        ...theme.spacing.marginTop(1),
      },
    }
  }),
  'options:vertical': createRadioInputVariant((theme) => ({
    innerWrapper: {
      ...theme.presets.column,
      ...theme.presets.alignStart,
    },
  })),
}

StyleRegistry.registerVariants('RadioInput', RadioInputStyles)
