import { api } from '@/config'
import { Profile } from '@/types'
import { PaginationResponse, QueryManager, waitFor } from '@codeleap/common'
import { queryClient } from './queryClient'

export type Post = {
  id: number
  created_datetime: string
  title: string
  content: string
  profile: Profile
  likes?: number
  is_liked: boolean
  favorites: number
  is_favorited: boolean
}

const BASE_URL = 'crudexample/'

type PostFilters = {
  search?: string
  profile_id?: string
  likes?: boolean
  favorites?: boolean
  sort?: 'asc' | 'desc'
}

/*
  "QueryManager" is a class that helps us to manage the state of a list of items.

  It has a lot of options to customize the behavior of the list, like:
  - Optimistic updates
  - Pagination
  - Filters, which work can maintain state across refreshes, with multiple combinations, etc.
  - Sorting
  - Extra actions (everything that is not Create, Read, Update, or Delete)
  - etc.

  It also has a lot of useful methods to interact with the list, like:
  - Refreshing the list
  - Refreshing a single item
  - Deleting an item
  - Updating an item
  - Getting an item, with a fallback to the server if it's not in the list

  It also has a lot of useful properties, like:
  - The list of items
  - A map of items by id
  - Optional metadata, like the number of items, the number of pages, etc., which can be customised

  The aim of this is to have a single source of truth for the state of the list, and to have a single
  place to interact with it, so that we can have a consistent behavior across the app.

  By leveraging react-query, we can also have a lot of useful features, like:
  - Automatic caching
  - Automatic refetching
  - Automatic retries
  - Automatic loading and error states
  - etc.

  Other noteworthy features:
  - Everything is globally accessible, so we can access the list from anywhere in the app. But there are also hooks to make it easier to use in components
  - The lists for every possible filter combination are cached and updated simultaneously, so we can have a list of posts filtered by a search term,
  and another list filtered by a profile, and when a post is updated, both lists will be reflect the change
*/

export const postsManager = new QueryManager({
  itemType: {} as Post, // We need to pass the item's type like this to make intellisense work because of a typescript limitation
  name: 'posts', // This will be used as an identifier for our item store in the react-query cache
  queryClient: queryClient.client,
  
// This is the function that will be called to fetch the items from the server. This is called internally by QueryManager
  listItems: async (limit, offset, filters:PostFilters) => {

    const response = await api.get<PaginationResponse<Post>>(BASE_URL, {
      params: {
        limit,
        offset,
        ...filters,
      },
    })

  return response.data
},
createItem: async (data) => {
  console.log('Create post', data)

  if (data.title.includes('Error')) {
    throw new Error('Error creating post')
  }
  
    // Simulate a delay
    await waitFor(3000)

    const response = await api.post(BASE_URL, data)

    return response.data
  },
  updateItem: async (data) => {
    await waitFor(3000)
    const response = await api.patch<Post>(`${BASE_URL}/${data.id}/`, data)
    // When dealing with optimistic updates, any error thrown here will be caught and the item will be reverted to its previous state
    if (data.title.includes('Error')) {
      throw new Error('Error updating post')
    }
    return response.data
  },
  deleteItem: async (item) => {

    if (postsManager.meta.throwErrorForDelete) {
      throw new Error('Error deleting post')
    }

    // Simulate a delay
    await waitFor(3000)

    await api.delete(`${BASE_URL}/${item.id}/`)

    return item
  },
  retrieveItem: async (id) => {
    const response = await api.get<Post>(`${BASE_URL}${id}/`)

    return response.data
  },
  // Optimistic updates can be controlled from here as well as from individual function calls
  // creation: {
  //   optimistic: true,
  // },
  // update: {
  //   optimistic: true,
  // },
  // deletion: {
  //   optimistic: true,
  // },

  // Metadata is any sort of additional settings or data we want to keep track of. This can be acessed from anywhere that has access to the QueryManager instance
  // NOTE: This metadata is only intended for the settings in the CRUD example, you don't need to configure such things for your own use case
  initialMeta: {
    optimisticLike: true,
    throwErrorForDelete: false,
    optimisticFavorite: true,
  },
  // Actions are any sort of function that we want to be able to call while having access to the QueryManager instance.
  // It also simplifies the process of configuring optimistic updates for these actions.
  // They receive the first argument as the QueryManager instance, and the rest of the arguments are passed from the call to the action
  // So for instance if we call postsManager.actions.markRead(1), the arguments passed to the action will be (postsManager, 1)
  actions: {
    async markRead(manager, postId: Post['id']) {
      await api.patch('notifications/read_post/', {
        id: postId,
      })

      await manager.refreshItem(postId)
    },
    async toggleLike(manager, postId: Post['id']) {

      const post = await manager.getItem(postId, {
        fetchOnNotFoud: false,
        forceRefetch: true,
      })

      const shouldBeLiked = !post.is_liked

      const isOptimistic = manager.meta.optimisticLike

      if (isOptimistic) {
        const updatedPost = {
          ...post,
          is_liked: shouldBeLiked,
          likes: shouldBeLiked ? post.likes + 1 : post.likes - 1,
        }

        manager.setItem(updatedPost)
      }
      try {
        if (shouldBeLiked) {
          if (isOptimistic) {
            // The backend is actually very fast, so we need to simulate a delay to see the optimistic update
            await waitFor(3000)

          }
          await api.post(`${BASE_URL}like/`, {
            post: post.id,
          })
        } else {
          if (isOptimistic) {
            await waitFor(3000)
          }
          await api.delete(`${BASE_URL}like/${post.id}/`, {
            validateStatus: () => true,
          })
        }

      } catch (e) {
        if (isOptimistic) {
          manager.setItem(post)

        }
      }

      if (!isOptimistic) {
        await manager.refreshItem(postId)
      }

    },
    async toggleFavorite(manager, postId: Post['id']) {
      const post = await manager.getItem(postId, {
        fetchOnNotFoud: false,
        forceRefetch: true,
      })

      const shouldBeLiked = !post.is_favorited

      if (manager.meta.optimisticFavorite) {
        const updatedPost = {
          ...post,
          is_favorited: shouldBeLiked,
          favorites: shouldBeLiked ? post.favorites + 1 : post.favorites - 1,
        }

        manager.setItem(updatedPost)
      }
      try {
        if (shouldBeLiked) {
          await api.post(`${BASE_URL}favorite/`, {
            post: post.id,
          })
        } else {
          await api.delete(`${BASE_URL}favorite/${post.id}/`, {
            validateStatus: () => true,
          })
        }

      } catch (e) {
        if (manager.meta.optimisticFavorite) {
          manager.setItem(post)
        }
      }

      if (!manager.meta.optimisticFavorite) {
        await manager.refreshItem(postId)
      }

    },
  },
})

// See usage of hooks at src/pages/crud/index.tsx
