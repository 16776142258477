import React, { useMemo, useRef, useState } from 'react'
import { ReactSVG } from 'react-svg'
import { Select, View, Icon, TextInput, Text, Touchable } from '@/components'
import { AppIcon, I18N } from '@/config'
import { TypeGuards, onUpdate, arePropsEqual, useI18N } from '@codeleap/common'
import { usePhoneOptions } from '@/utils'
import { FlagProps, PhoneSelectorProps, PhoneSelectorItemProps, PhoneInputProps, PhoneOption } from './types'
import { createStyles } from '@codeleap/styles'

function Flag(props: FlagProps) {
  const { flag, code, icon } = props

  return (
    <View style={styles.wrapperFlag}>
      <View style={styles.innerWrapperFlag}>
        {icon ? (
          <Icon debugName={'PhoneInput phone icon'} name={icon} style={['size:3', styles.icon]} />
        ) : (
          <img style={styles.flag} src={flag} />
        )}
      </View>

      <Text text={code} style={['p1', 'color:neutral10', 'marginLeft:1']} />
    </View>
  )
}

const PhoneSelector = (props: PhoneSelectorProps) => {
  const { isOpen, setIsOpen, options = [], selectedPhone, setSelectedPhone, selectRef, ...rest } = props

  return <Select
    options={options}
    debugName='Phone number input selector'
    title={I18N.t('components.phoneInput.selectorTitle')}
    placeholder={I18N.t('components.phoneInput.selectorSearchInputPlaceholder')}
    value={selectedPhone}
    onValueChange={setSelectedPhone}
    searchable
    selectRef={selectRef}
    onBlur={() => setIsOpen(false)}
    {...rest}
  />
}

const PhoneSelectorItem = React.memo<PhoneSelectorItemProps>(({ data, isSelected }) => {
  return (
    <View style={[styles.itemWrapper, isSelected && styles['itemWrapper:selected']]}>
      <View style={styles.innerWrapper}>
        <View style={styles.innerWrapperFlag}>
          <ReactSVG style={styles.flag} src={data.flag} />
        </View>
        <Text text={data.code} style={['p1', 'color:neutral10', 'marginLeft:1']} />
        <Text text={data.description} style={['marginLeft:0.5', isSelected && 'color:neutral10']} />
      </View>
      {isSelected ? <Icon debugName={'PhoneInput check'} name='check' style={['size:2', 'neutral10']} /> : null}
    </View>
  )
}, (prev, next) => arePropsEqual(prev, next, { check: ['isSelected'] }))

const removeNonNumericCharsRegex = /\D/g

export const PhoneInput = (props: PhoneInputProps) => {
  const {
    value,
    onChangeText,
    phone = null,
    selectProps = {},
    defaultLocale,
    disableInternalization,
    initialLocale,
    validate,
    disableValidatorPhone = false,
    isPhoneValid,
    setPhoneValid = null,
    countrySelectable = true,
    ...rest
  } = props

  const { t } = useI18N()

  const { options, phones, localePhone } = usePhoneOptions({
    defaultLocale,
    disableInternalization,
    initialLocale,
  })

  const [selectedPhone, setSelectedPhone] = useState(localePhone?.value)
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null)

  const isPhoneValidOrInputOpen = isPhoneValid || (!isPhoneValid && isOpen)

  const hasPhone = !TypeGuards.isNil(phone)

  const currentPhone = React.useMemo(() => {
    if (hasPhone) return phone
    return phones?.[selectedPhone]
  }, [selectedPhone])

  const validatorPhone = disableValidatorPhone ? null : (value: string) => {
    const numericValue = value.replace(removeNonNumericCharsRegex, '')
    const currentPhoneNumericValue = currentPhone?.mask?.replace(removeNonNumericCharsRegex, '')
    const match = (numericValue.length >= currentPhoneNumericValue?.length)
    const invalidPhoneMessage = !match ? t('components.phoneInput.invalidPhoneMessage') : ''

    if (setPhoneValid) setPhoneValid(match)

    return {
      valid: match,
      message: invalidPhoneMessage,
    }
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const currentFlag = useMemo(() => {
    return (
      <Touchable debugName='phoneInput:flag' onPress={toggle}>
        <Flag flag={currentPhone?.flag} icon={(!countrySelectable && 'phone') as AppIcon} code={currentPhone?.code} />
      </Touchable>
    )
  }, [currentPhone?.flag])

  onUpdate(() => {
    if (isOpen && selectRef.current) {
      selectRef.current.focus()
    }
  }, [isOpen])

  return <>
    <TextInput
      label={t('components.phoneInput.label')}
      description={countrySelectable && currentPhone?.description}
      placeholder={currentPhone?.maskPlaceholder}
      debugName={'Phone number input'}
      value={value}
      onChangeText={onChangeText}
      leftIcon={currentFlag as any}
      // @ts-ignore
      rightIcon={(!hasPhone && countrySelectable) && {
        name: 'chevron-down',
        onPress: toggle,
      }}
      masking={{
        type: 'custom',
        options: {
          mask: currentPhone?.applyMask,
        },
      }}
      validate={validatorPhone}
      // @ts-ignore
      style={[isPhoneValidOrInputOpen ? 'noError' : '', styles.input]}
      {...rest}
    />

    <View style={{ width: '100%', display: isOpen && countrySelectable ? 'flex' : 'none' }}>
      <PhoneSelector
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectRef={selectRef}
        selectedPhone={selectedPhone}
        setSelectedPhone={(value) => {
          if (!value) return
          setSelectedPhone(value)
          setIsOpen(false)
          onChangeText('')
        }}
        // @ts-ignore
        options={options}
        loadInitialValue
        backspaceRemovesValue={false}
        escapeRemoves={false}
        deleteRemoves={false}
        style={[isPhoneValidOrInputOpen && 'marginTop:1', { wrapper: styles.wrapper, innerWrapper: styles.innerWrapper }]}
        renderItem={(option: PhoneOption) => {
          return <PhoneSelectorItem isSelected={option?.isSelected} data={option?.data} />
        }}
        {...selectProps}
      />
    </View>
  </>
}

const styles = createStyles(theme => ({
  wrapper: {
    ...theme.presets.fullWidth,
  },
  wrapperFlag: {
    width: 'auto',
    height: theme.values.itemHeight.default - theme.spacing.value(1),
    ...theme.presets.center,
    ...theme.presets.row,
  },
  itemWrapper: {
    height: theme.values.itemHeight.default - theme.spacing.value(1),
    borderRadius: theme.borderRadius.tiny,
    cursor: 'pointer',
    ...theme.presets.fullWidth,
    ...theme.presets.center,
    ...theme.presets.row,
    ...theme.spacing.paddingHorizontal(1),
  },
  'itemWrapper:selected': {
    backgroundColor: theme.colors.primary3,
  },
  innerWrapperFlag: {
    ...theme.presets.center,
    width: theme.values.itemHeight.default - theme.spacing.value(2),
    height: theme.values.itemHeight.default - theme.spacing.value(3),
  },
  flag: {
    width: '100%',
    ...theme.spacing.marginTop(0.5),
  },
  icon: {
    color: theme.colors.neutral6,
    fill: theme.colors.neutral6,
  },
  input: {
    ...theme.spacing.marginLeft(0.5),
  },
  innerWrapper: {
    ...theme.presets.fullWidth,
  },
}))
