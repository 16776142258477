import { NumberIncrementComposition } from '@codeleap/web'
import { customTextStyles } from './Text'
import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'

const createNumberIncrementVariant = createStyles<NumberIncrementComposition>

export const NumberIncrementStyles = {
  default: createNumberIncrementVariant((theme) => {
    const height = theme.values.itemHeight.default
    return {
      wrapper: {
        ...theme.presets.column,
        width: 'auto',
      },
      label: {
        ...customTextStyles('p2'),
        color: theme.colors.neutral7,
        ...theme.spacing.marginBottom(1),
      },
      description: {
        ...customTextStyles('p4'),
        color: theme.colors.neutral7,
        ...theme.spacing.marginBottom(1),
      },
      errorMessage: {
        ...customTextStyles('p4'),
        color: theme.colors.destructive2,
        ...theme.spacing.marginTop(1),
      },
      input: {
        ...customTextStyles('p1'),
        borderWidth: 0,
        textAlign: 'center',
        width: '100%',
        flex: 1,
      },
      'input:disabled': {
        color: theme.colors.neutral5,
      },
      'input:error': {
        color: theme.colors.destructive2,
      },
      placeholder: {
        color: theme.colors.neutral7,
      },
      'placeholder:disabled': {
        color: theme.colors.neutral5,
      },
      innerWrapper: {
        ...theme.spacing.paddingHorizontal(2),
        ...theme.spacing.paddingVertical(2),
        ...theme.presets.row,
        ...theme.presets.alignCenter,
        ...theme.presets.justifySpaceBetween,
        maxHeight: height,
        minHeight: height,
        borderRadius: theme.borderRadius.small,
        backgroundColor: theme.colors.neutral1,
        ...theme.border({ color: theme.colors.neutral5, width: theme.values.borderWidth.small }),
      },
      'innerWrapper:cursor': {
        cursor: 'text',
      },
      'innerWrapper:focus': {
        ...theme.border({ color: theme.colors.primary3, width: theme.values.borderWidth.small }),
      },
      'innerWrapper:error': {
        ...theme.border({ color: theme.colors.destructive2, width: theme.values.borderWidth.small }),
      },
      'innerWrapper:disabled': {
        ...theme.border({ color: theme.colors.neutral2, width: theme.values.borderWidth.small }),
        cursor: 'not-allowed',
      },
      iconIcon: {
        height: theme.values.iconSize[3],
        width: theme.values.iconSize[3],
        color: theme.colors.primary3,
      },
      'leftIconIcon:disabled': {
        color: theme.colors.neutral5,
        cursor: 'not-allowed',
      },
      'rightIconIcon:disabled': {
        color: theme.colors.neutral5,
        cursor: 'not-allowed',
      },
      iconTouchableWrapper: {
        ...theme.spacing.padding(0),
        height: theme.values.iconSize[3],
        width: theme.values.iconSize[3],
        borderWidth: 0,
        backgroundColor: theme.colors.neutral1,
      },
      leftIconTouchableWrapper: {
        ...theme.spacing.marginRight(2),
      },
      'leftIconTouchableWrapper:disabled': {
        backgroundColor: theme.colors.neutral1,
      },
      rightIconTouchableWrapper: {
        ...theme.spacing.marginLeft(2),
      },
      'rightIconTouchableWrapper:disabled': {
        backgroundColor: theme.colors.neutral1,
      },
    }
  }),
  noError: createNumberIncrementVariant((theme) => ({
    errorMessage: {
      display: 'none',
    },
  })),
  line: createNumberIncrementVariant((theme) => ({
    innerWrapper: {
      ...theme.border({ color: theme.colors.neutral5, width: 0 }),
      ...theme.border({ color: theme.colors.neutral5, width: theme.values.borderWidth.small, directions: ['bottom'] }),
      borderRadius: 0,
    },
    'innerWrapper:focus': {
      ...theme.border({ color: theme.colors.neutral5, width: 0 }),
      ...theme.border({ color: theme.colors.primary3, width: theme.values.borderWidth.small, directions: ['bottom'] }),
    },
    'innerWrapper:error': {
      ...theme.border({ color: theme.colors.neutral5, width: 0 }),
      ...theme.border({ color: theme.colors.destructive2, width: theme.values.borderWidth.small, directions: ['bottom'] }),
    },
    'innerWrapper:disabled': {
      ...theme.border({ color: theme.colors.neutral5, width: 0 }),
      ...theme.border({ color: theme.colors.neutral2, width: theme.values.borderWidth.small, directions: ['bottom'] }),
    },
  })),
  pill: createNumberIncrementVariant((theme) => ({
    innerWrapper: {
      borderRadius: theme.borderRadius.rounded,
    },
  })),
  normal: createNumberIncrementVariant((theme) => ({
    innerWrapper: {
      borderRadius: theme.borderRadius.small,
    },
  })),
}

StyleRegistry.registerVariants('NumberIncrement', NumberIncrementStyles)
