import { shadeColor } from '@codeleap/common'
import { createStyles } from '@codeleap/styles'
import { LoadingOverlayComposition } from '@codeleap/web'
import { StyleRegistry } from '../styles'

const createLoadingOverlayVariant = createStyles<LoadingOverlayComposition>

export const LoadingOverlayStyles = {
  default: createLoadingOverlayVariant((theme) => ({
    wrapper: {
      backgroundColor: shadeColor(theme.colors.neutral3, 0, 0.5),
      backdropFilter: 'blur(5px)',
      position: 'absolute',
      ...theme.presets.whole,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0,
      pointerEvents: 'none',
    },
    'wrapper:visible': {
      opacity: 1,
      pointerEvents: 'auto',
    },
  })),
}

StyleRegistry.registerVariants('LoadingOverlay', LoadingOverlayStyles)
