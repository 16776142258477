"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformObject = exports.objectPickBy = exports.extractKey = exports.createSettings = exports.traverse = exports.optionalObject = exports.isValuePrimitive = exports.objectPaths = exports.deepGet = exports.deepSet = exports.mapObject = exports.deepMerge = void 0;
function deepMerge(base, changes) {
    var e_1, _a;
    if (base === void 0) { base = {}; }
    if (changes === void 0) { changes = {}; }
    var obj = __assign({}, base);
    var changeEntries = [];
    try {
        changeEntries = Object.entries(changes);
    }
    catch (e) {
        return changes;
    }
    var checkForFile = typeof window !== 'undefined' && changes instanceof File;
    try {
        for (var changeEntries_1 = __values(changeEntries), changeEntries_1_1 = changeEntries_1.next(); !changeEntries_1_1.done; changeEntries_1_1 = changeEntries_1.next()) {
            var _b = __read(changeEntries_1_1.value, 2), key = _b[0], value = _b[1];
            obj[key] =
                typeof value === 'object' && !Array.isArray(value) && !(value instanceof Date || checkForFile)
                    ? deepMerge(obj[key], changes[key])
                    : value;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (changeEntries_1_1 && !changeEntries_1_1.done && (_a = changeEntries_1.return)) _a.call(changeEntries_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return obj;
}
exports.deepMerge = deepMerge;
function mapObject(obj, callback) {
    return Object.entries(obj).map(function (args) { return callback(args); });
}
exports.mapObject = mapObject;
var deepSet = function (_a) {
    var _b = __read(_a, 2), path = _b[0], value = _b[1];
    var parts = path.split('.');
    var newObj = Array.isArray(value) ? [] : {};
    if (parts.length === 1) {
        newObj[parts[0]] = value;
    }
    else {
        newObj[parts[0]] = (0, exports.deepSet)([parts.slice(1).join('.'), value]);
    }
    return newObj;
};
exports.deepSet = deepSet;
var deepGet = function (path, obj) {
    var e_2, _a;
    var parts = path.split('.');
    var newObj = __assign({}, obj);
    try {
        for (var parts_1 = __values(parts), parts_1_1 = parts_1.next(); !parts_1_1.done; parts_1_1 = parts_1.next()) {
            var prop = parts_1_1.value;
            newObj = newObj[prop];
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (parts_1_1 && !parts_1_1.done && (_a = parts_1.return)) _a.call(parts_1);
        }
        finally { if (e_2) throw e_2.error; }
    }
    return newObj;
};
exports.deepGet = deepGet;
function objectPaths(obj) {
    var paths = [];
    Object.entries(obj).forEach(function (_a) {
        var _b = __read(_a, 2), key = _b[0], value = _b[1];
        if (!Array.isArray(value) && typeof value === 'object') {
            paths = __spreadArray(__spreadArray([], __read(paths), false), __read(objectPaths(value).map(function (k) { return "".concat(key, ".").concat(k); })), false);
        }
        else {
            paths.push(key);
        }
    });
    return paths;
}
exports.objectPaths = objectPaths;
function isValuePrimitive(a) {
    return ['string', 'number', 'boolean'].includes(typeof a);
}
exports.isValuePrimitive = isValuePrimitive;
function optionalObject(condition, ifTrue, ifFalse) {
    return condition ? ifTrue : ifFalse;
}
exports.optionalObject = optionalObject;
function traverse(obj, callback, args) {
    var e_3, _a;
    if (obj === void 0) { obj = {}; }
    var isPrimitive = isValuePrimitive(obj);
    var info = __assign({ path: [], depth: 0, key: '', type: typeof obj, value: obj, primitive: isPrimitive }, args);
    if (isPrimitive) {
        callback(__assign(__assign({}, info), { depth: info.depth }));
    }
    else {
        try {
            for (var _b = __values(Object.entries(obj || {})), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                var isPrimitive_1 = isValuePrimitive(value);
                if (!isPrimitive_1) {
                    callback(__assign(__assign({}, info), { key: key, value: value, type: typeof value, primitive: isPrimitive_1, path: __spreadArray(__spreadArray([], __read(info.path), false), [key], false) }));
                }
                traverse(value, callback, __assign(__assign({}, info), { key: key, value: value, type: typeof value, primitive: isValuePrimitive(value), path: __spreadArray(__spreadArray([], __read(info.path), false), [key], false), depth: info.depth + 1 }));
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    }
}
exports.traverse = traverse;
function createSettings(a) {
    return a;
}
exports.createSettings = createSettings;
function extractKey(obj) {
    if (obj.id) {
        return obj.id;
    }
}
exports.extractKey = extractKey;
function objectPickBy(obj, predicate) {
    var _a;
    var result = {};
    for (var key in obj) {
        if (((_a = obj === null || obj === void 0 ? void 0 : obj.hasOwnProperty) === null || _a === void 0 ? void 0 : _a.call(obj, key)) && (predicate === null || predicate === void 0 ? void 0 : predicate(obj === null || obj === void 0 ? void 0 : obj[key], key))) {
            result[key] = obj === null || obj === void 0 ? void 0 : obj[key];
        }
    }
    return result;
}
exports.objectPickBy = objectPickBy;
function transformObject(obj, predicate) {
    var result = {};
    for (var key in obj) {
        var _a = __read(predicate === null || predicate === void 0 ? void 0 : predicate(obj === null || obj === void 0 ? void 0 : obj[key], key), 2), newKey = _a[0], newValue = _a[1];
        result[newKey] = newValue;
    }
    return result;
}
exports.transformObject = transformObject;
