import { TextEditorComposition } from '@codeleap/web'
import { customTextStyles } from './Text'
import { StyleRegistry } from '../styles'
import { createStyles } from '@codeleap/styles'

const createTextEditorVariant = createStyles<TextEditorComposition>

const defaultEditorStyles = {
  default: createTextEditorVariant(theme => ({
    wrapper: {
      ...theme.spacing.paddingRight(1),
      ...theme.spacing.paddingLeft(3),
      ...theme.spacing.paddingVertical(2),
      fontFamily: theme.typography.base.fontFamily,
      fontSize: theme.typography.base.styles.p1.size,
      fontWeight: theme.typography.base.styles.p1.weight,
      borderBottomLeftRadius: theme.borderRadius.small,
      borderBottomRightRadius: theme.borderRadius.small,
      ...theme.border({ color: theme.colors.neutral5, width: 1, directions: ['bottom', 'left', 'right'] }),

      h1: { fontSize: theme.typography.base.styles.h1.size },
      h2: { fontSize: theme.typography.base.styles.h2.size },
      h3: { fontSize: theme.typography.base.styles.h3.size },
      h4: { fontSize: theme.typography.base.styles.h4.size },
      h5: { fontSize: theme.typography.base.styles.h5.size },
      h6: { fontSize: theme.typography.base.styles.h6.size },
      'ul, ol': {
        ...theme.spacing.marginLeft(3),
      },

      'p.placeholder::before': {
        color: theme.colors.neutral7,
        content: 'attr(data-placeholder)',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
      },
    },
  })),
}

export const TextEditorStyles = {
  default: createTextEditorVariant((theme) => ({
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.column,
    },
    'wrapper:error': {
      '.ProseMirror:focus': {
        outline: `auto 2px ${theme.colors.destructive2}`,
      },
    },
    editor: {
      ...defaultEditorStyles.default.wrapper,
    },
    'editor:disabled': {
      ...defaultEditorStyles.default.wrapper,
      cursor: 'not-allowed',
      ...theme.border({ color: theme.colors.neutral2, width: 1, directions: ['bottom', 'left', 'right'] }),
      color: theme.colors.neutral5,
    },
    'editor:error': {
      ...defaultEditorStyles.default.wrapper,
      ...theme.border({ color: theme.colors.destructive2, width: 1, directions: ['bottom', 'left', 'right', 'top'] }),
    },
    'errorMessage:error': {
      ...customTextStyles('p4'),
      color: theme.colors.destructive2,
      ...theme.spacing.marginTop(1),
    },
    floatingMenu: {},
    bubbleMenu: {},
    bubbleMenuInnerWrapper: {},
    floatingMenuInnerWrapper: {},
  })),
}

StyleRegistry.registerVariants('TextEditor', TextEditorStyles)
