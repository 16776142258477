"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.borderCreator = void 0;
var themeStore_1 = require("./themeStore");
var utils_1 = require("./utils");
var borderCreator = function (args) {
    var e_1, _a;
    var _b, _c;
    var colorKey = args.color, _d = args.width, width = _d === void 0 ? 1 : _d, _e = args.style, style = _e === void 0 ? 'solid' : _e, _f = args.directions, directions = _f === void 0 ? ['left', 'top', 'bottom', 'right'] : _f;
    var theme = themeStore_1.themeStore.getState().current;
    var color = (_c = (_b = theme === null || theme === void 0 ? void 0 : theme['colors']) === null || _b === void 0 ? void 0 : _b[colorKey]) !== null && _c !== void 0 ? _c : colorKey;
    var borderStyles = {};
    try {
        for (var directions_1 = __values(directions), directions_1_1 = directions_1.next(); !directions_1_1.done; directions_1_1 = directions_1.next()) {
            var direction = directions_1_1.value;
            var property = "border".concat((0, utils_1.capitalize)(direction));
            borderStyles["".concat(property, "Color")] = color;
            borderStyles["".concat(property, "Width")] = width;
            if (typeof localStorage !== 'undefined') {
                borderStyles["".concat(property, "Style")] = style;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (directions_1_1 && !directions_1_1.done && (_a = directions_1.return)) _a.call(directions_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return borderStyles;
};
exports.borderCreator = borderCreator;
