"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStyles = void 0;
var themeStore_1 = require("./themeStore");
function createStyles(styles) {
    var compute = function () {
        var styleObj = {};
        var current = themeStore_1.themeStore.getState().current;
        if (typeof styles === 'function') {
            styleObj = styles(current);
        }
        else {
            styleObj = styles;
        }
        return styleObj;
    };
    // We use a proxy here so that the color scheme is recomputed every time the
    // theme changes. This is necessary because the theme is a singleton which does not cause
    // a re-render when it changes. The end-user will only have to worry about remounting the root component
    // when the theme changes in order to get the new color scheme due to this proxy.
    return new Proxy(compute(), {
        get: function (target, prop) {
            return compute()[prop];
        },
    });
}
exports.createStyles = createStyles;
