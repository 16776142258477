import { ReactQuery, I18NProvider, GlobalContextProvider } from '@codeleap/common'
import { Settings, React, I18N, logger } from '@/config'
import { Provider } from 'react-redux'
import { store } from '@/redux'
import { APIClient } from '@/services'
import { DebugModal, GlobalAlert, GlobalStyle, ShareModal, Onboarding, AppStatusOverlay } from '@/components'

const App = ({ children }: React.PropsWithChildren) => {
  APIClient.Session.useSession(true)

  return (
    <>
      <GlobalStyle />
      {children}
      <AppStatusOverlay />
      <GlobalAlert />
      {Settings.Environment.IsDev ? <DebugModal /> : null}
      <Onboarding />
      <ShareModal />
    </>
  )
}

export const AppWrapper = ({ children }: React.PropsWithChildren) => {
  return (
    <ReactQuery.QueryClientProvider client={APIClient.queryClient.client}>
      <GlobalContextProvider isBrowser settings={Settings} logger={logger}>
        <Provider store={store}>
          <I18NProvider i18n={I18N}>
            <App>
              {children}
            </App>
          </I18NProvider>
        </Provider>
      </GlobalContextProvider>
    </ReactQuery.QueryClientProvider>
  )
}
