import { View, Text, Link } from '@/components'
import { StyleSheets } from '@/config'
import { StylesOf } from '@codeleap/common'

export type FooterNavigateProps = {
  styles?: StylesOf<StyleSheets.FooterComposition>
}

type NavigateLink = {
  text: string
  route?: AppRoute
  to?: string
}

const links: Record<string, NavigateLink[]> = {
  pages: [
    { text: 'Home', route: 'Home' },
    { text: 'Playground', route: 'Playground' },
  ],
  others: [
    { text: 'About', route: 'About' },
    { text: 'Docs', to: 'http://docs.codeleap.co.uk/' },
  ],
}

function Column({ links, title, styles }) {
  const RenderLink = ({ link }) => (
    <Link
      route={link?.route}
      to={link?.to}
      openNewTab={!!link?.to}
      text={link?.text}
      style={styles.navigateLink}
    />
  )

  return (
    <View style={styles.navigateColumn}>
      <Text style={styles.navigateTitle} text={title} />
      {links.map((link, index) => <RenderLink link={link} key={index + 'footer-link'} />)}
    </View>
  )
}

export const FooterNavigate = (props: FooterNavigateProps) => {
  const { styles } = props

  return (
    <View style={styles.navigateWrapper}>
      <Column
        styles={styles}
        title={'Pages'}
        links={links?.pages}
      />
      <Column
        styles={styles}
        title='Terms & Conditions'
        links={links?.others}
      />
    </View>
  )
}
