"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.spacingFactory = void 0;
var spacing_1 = require("../types/spacing");
var shortMapValues = {
    'x': 'Horizontal',
    'y': 'Vertical',
    'l': 'Left',
    'r': 'Right',
    't': 'Top',
    'b': 'Bottom',
    'm': 'margin',
    'p': 'padding'
};
function spacingFactory(base, spacingProperty, isShort) {
    var _a, e_1, _b;
    if (isShort === void 0) { isShort = false; }
    var property = isShort ? shortMapValues[spacingProperty] : spacingProperty;
    var positions = isShort ? spacing_1.spacingShortVariants : spacing_1.spacingVariants;
    var spacings = (_a = {},
        _a["".concat(spacingProperty)] = function (n) {
            var _a;
            return (_a = {},
                _a["".concat(property)] = base * Number(n),
                _a);
        },
        _a);
    var _loop_1 = function (_position) {
        var position = isShort ? shortMapValues[_position] : _position;
        var key = "".concat(spacingProperty).concat(_position);
        var getter = null;
        switch (position) {
            case 'Horizontal':
                getter = function (value) {
                    var _a;
                    return (_a = {},
                        _a["".concat(property, "Left")] = value,
                        _a["".concat(property, "Right")] = value,
                        _a);
                };
                break;
            case 'Vertical':
                getter = function (value) {
                    var _a;
                    return (_a = {},
                        _a["".concat(property, "Top")] = value,
                        _a["".concat(property, "Bottom")] = value,
                        _a);
                };
                break;
            case '':
                getter = function (value) {
                    var _a;
                    return (_a = {},
                        _a["".concat(property, "Top")] = value,
                        _a["".concat(property, "Left")] = value,
                        _a["".concat(property, "Right")] = value,
                        _a["".concat(property, "Bottom")] = value,
                        _a);
                };
                break;
            default:
                getter = function (value) {
                    var _a;
                    return (_a = {},
                        _a["".concat(property).concat(position)] = value,
                        _a);
                };
                break;
        }
        spacings[key] = function (n) {
            if (n == 'auto')
                return getter('auto');
            var value = base * Number(n);
            return getter(value);
        };
    };
    try {
        for (var positions_1 = __values(positions), positions_1_1 = positions_1.next(); !positions_1_1.done; positions_1_1 = positions_1.next()) {
            var _position = positions_1_1.value;
            _loop_1(_position);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (positions_1_1 && !positions_1_1.done && (_b = positions_1.return)) _b.call(positions_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return spacings;
}
exports.spacingFactory = spacingFactory;
