import React from 'react'
import { TypeGuards, useBooleanToggle } from '@codeleap/common'
import { Collapse, useStylesFor, ButtonProps, CollapseProps } from '@codeleap/web'
import { StyledProp, StyledComponent, concatStyles, useCompositionStyles, mergeStyles } from '@codeleap/styles'
import { View, Button } from '@/components'
import { NavigationComponent, useNavigation } from '..'
import { StyleSheets } from '@/config'

type SectionProps = Omit<ButtonProps, 'style'> & {
  title?: string
  collapsible?: boolean
  initiallyCollapsed?: boolean
  collapseProps?: Partial<CollapseProps>
  style?: StyledProp<StyleSheets.NavigationComposition>
}

export const Section: StyledComponent<typeof StyleSheets.NavigationStyles, SectionProps> = (props: SectionProps) => {
  const {
    title = null,
    collapsible = false,
    initiallyCollapsed = true,
    children,
    collapseProps = {},
    style,
    ...rest
  } = props

  const { style: containerStyle } = useNavigation()

  const styles = useStylesFor(NavigationComponent.styleRegistryName, concatStyles<StyleSheets.NavigationComposition>([containerStyle, style]))

  const [open, toggle] = useBooleanToggle(!initiallyCollapsed)

  const rightIcon = collapsible ? (open ? 'chevron-up' : 'chevron-down') : undefined

  const compositionStyles = useCompositionStyles(['title', 'collapse'], styles)

  const titleStyles = {
    ...compositionStyles.title,
    wrapper: {
      ...compositionStyles.title?.['wrapper'],
      ...(collapsible ? compositionStyles.title?.['wrapper:collapsible'] : {}),
    },
    text: {
      ...compositionStyles.title?.['text'],
      ...(collapsible ? compositionStyles.title?.['text:collapsible'] : {}),
    }
  }

  const collapseStyles = {
    ...compositionStyles.collapse,
    wrapper: {
      ...compositionStyles.collapse?.['wrapper'],
      ...(collapsible ? compositionStyles.collapse?.['wrapper:collapsible'] : {}),
    }
  }

  return (
    <View style={styles.section}>
      {TypeGuards.isString(title) ? (
        <Button
          text={title}
          rightIcon={rightIcon}
          onPress={collapsible ? toggle : undefined}
          {...rest}
          style={titleStyles}
        />
      ) : null}

      <Collapse
        open={collapsible ? open : true}
        {...collapseProps}
        style={collapseStyles}
      >
        {children}
      </Collapse>
    </View>
  )
}
