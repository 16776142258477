"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTheme = void 0;
var borderCreator_1 = require("./borderCreator");
var mediaQuery_1 = require("./mediaQuery");
var multiplierProperty_1 = require("./multiplierProperty");
var defaultVariants_1 = require("./defaultVariants");
var spacing_1 = require("./spacing");
var themeStore_1 = require("./themeStore");
var createTheme = function (theme, colorSchemaPersistor) {
    var _a, _b, _c, _d, _e, _f;
    var themeObj = {
        get currentColorScheme() {
            return themeStore_1.themeStore.getState().colorScheme;
        },
        breakpoints: (_a = theme.breakpoints) !== null && _a !== void 0 ? _a : {},
        get colors() {
            var _a;
            var colorScheme = themeStore_1.themeStore.getState().colorScheme;
            if (colorScheme === 'default')
                return theme.colors;
            return (_a = theme.alternateColors) === null || _a === void 0 ? void 0 : _a[colorScheme];
        },
        setColorScheme: function (colorScheme) {
            themeStore_1.themeStore.setState({ colorScheme: colorScheme });
            colorSchemaPersistor.set(colorScheme);
        },
        baseSpacing: theme.baseSpacing,
        value: function (n) {
            if (n === void 0) { n = 1; }
            return theme.baseSpacing * n;
        },
        spacing: __assign(__assign(__assign(__assign({ value: function (n) {
                if (n === void 0) { n = 1; }
                return theme.baseSpacing * n;
            }, gap: (0, multiplierProperty_1.multiplierProperty)(theme.baseSpacing, 'gap') }, (0, spacing_1.spacingFactory)(theme.baseSpacing, 'padding')), (0, spacing_1.spacingFactory)(theme.baseSpacing, 'margin')), (0, spacing_1.spacingFactory)(theme.baseSpacing, 'p', true)), (0, spacing_1.spacingFactory)(theme.baseSpacing, 'm', true)),
        inset: {
            top: (0, multiplierProperty_1.multiplierProperty)(theme.baseSpacing, 'top'),
            bottom: (0, multiplierProperty_1.multiplierProperty)(theme.baseSpacing, 'bottom'),
            left: (0, multiplierProperty_1.multiplierProperty)(theme.baseSpacing, 'left'),
            right: (0, multiplierProperty_1.multiplierProperty)(theme.baseSpacing, 'right')
        },
        presets: __assign(__assign({}, defaultVariants_1.defaultVariants), theme.presets),
        borderRadius: (_b = theme.borderRadius) !== null && _b !== void 0 ? _b : {},
        effects: (_c = theme.effects) !== null && _c !== void 0 ? _c : {},
        media: (0, mediaQuery_1.createMediaQueries)(theme.breakpoints),
        border: borderCreator_1.borderCreator,
        typography: (_d = theme.typography) !== null && _d !== void 0 ? _d : {},
        icons: theme.icons,
        values: (_e = theme.values) !== null && _e !== void 0 ? _e : {},
        sized: function (size) {
            var value = typeof size == 'number' ? size * theme.baseSpacing : size;
            return {
                width: value,
                height: value,
            };
        },
    };
    themeStore_1.themeStore.setState({
        current: themeObj,
        colorScheme: (_f = colorSchemaPersistor.get()) !== null && _f !== void 0 ? _f : 'default'
    });
    return themeObj;
};
exports.createTheme = createTheme;
