"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateTheme = void 0;
function validateTheme(theme) {
    var e_1, _a, e_2, _b;
    var colors = theme.colors;
    var requiredColors = Object.keys(colors);
    var alternateColors = theme.alternateColors;
    if (alternateColors) {
        try {
            for (var _c = __values(Object.entries(alternateColors)), _d = _c.next(); !_d.done; _d = _c.next()) {
                var _e = __read(_d.value, 2), colorSchemeName = _e[0], colorSchemeColors = _e[1];
                var colorSchemeColorNames = Object.keys(colorSchemeColors);
                try {
                    for (var requiredColors_1 = (e_2 = void 0, __values(requiredColors)), requiredColors_1_1 = requiredColors_1.next(); !requiredColors_1_1.done; requiredColors_1_1 = requiredColors_1.next()) {
                        var requiredColor = requiredColors_1_1.value;
                        if (!colorSchemeColorNames.includes(requiredColor)) {
                            throw new Error("Alternate color scheme ".concat(colorSchemeName, " is missing color ").concat(requiredColor));
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (requiredColors_1_1 && !requiredColors_1_1.done && (_b = requiredColors_1.return)) _b.call(requiredColors_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    return theme;
}
exports.validateTheme = validateTheme;
