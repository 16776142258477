"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOSAlert = exports.OSAlertStore = void 0;
var zustand_1 = require("zustand");
var utils_1 = require("../../utils");
exports.OSAlertStore = (0, zustand_1.create)(function (set) { return ({
    visible: false,
    toggle: function (to) {
        if (to === void 0) { to = null; }
        return set(function (state) { return ({
            visible: utils_1.TypeGuards.isBoolean(to) ? to : !state.visible,
        }); });
    },
    props: {},
    setProps: function (props) { return set(function () { return ({ props: props }); }); },
    open: function () { return set({ visible: true }); },
    close: function () { return set({ visible: false }); },
}); });
function CreateOSAlert(options) {
    if (options === void 0) { options = {}; }
    var _a = options.delay, delay = _a === void 0 ? 450 : _a;
    var trigger = function (props) {
        var alert = exports.OSAlertStore.getState();
        if (alert.visible) {
            alert.close();
            setTimeout(function () {
                alert.setProps(props);
                alert.open();
            }, delay);
        }
        else {
            alert.setProps(props);
            alert.open();
        }
    };
    function ask(_a) {
        var title = _a.title, body = _a.body, options = _a.options;
        if (!title) {
            title = 'Quick question';
        }
        trigger({
            title: title,
            body: body,
            options: options,
            type: 'ask',
            onAction: null,
            onDismiss: null,
        });
    }
    function OSError(args) {
        var title = args.title, body = args.body, options = args.options;
        if (!title) {
            title = 'Whoops!';
        }
        if (!body) {
            body = 'Something went wrong';
        }
        trigger({
            title: title,
            body: body,
            type: 'error',
            onAction: args.onDismiss,
            onDismiss: args.onDismiss,
            options: options,
        });
    }
    function warn(args) {
        var _a = args.title, title = _a === void 0 ? 'Hang on' : _a, _b = args.body, body = _b === void 0 ? 'Are you sure?' : _b, options = args.options, onAccept = args.onAccept, _c = args.onReject, onReject = _c === void 0 ? function () { return null; } : _c;
        trigger({
            title: title,
            body: body,
            options: options,
            type: 'warn',
            onAction: onAccept,
            onDismiss: onReject,
        });
    }
    function info(args) {
        var _a = args.title, title = _a === void 0 ? 'FYI' : _a, body = args.body, options = args.options, _b = args.onDismiss, onDismiss = _b === void 0 ? function () { return null; } : _b;
        trigger({
            title: title,
            body: body,
            options: options,
            type: 'info',
            onDismiss: onDismiss,
        });
    }
    function custom(args) {
        var title = args.title, body = args.body, type = args.type, rest = __rest(args, ["title", "body", "type"]);
        trigger(__assign({ title: title, body: body, type: type }, rest));
    }
    return {
        ask: ask,
        warn: warn,
        info: info,
        error: OSError,
        custom: custom,
    };
}
exports.CreateOSAlert = CreateOSAlert;
