"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makePerformanceInspector = void 0;
var utils_1 = require("../../utils");
var errors_1 = require("./errors");
var GlobalContext_1 = require("../../contexts/GlobalContext");
var renderCounter = {};
function makePerformanceInspector(settings) {
    /**
     * inspectRender monitors how much time a component render per second.
     * Use perf.inspectRender('ComponentName') inside a component to monitor it.
     * @param {string} name - Component name
     * @param {PerformanceInspector} options - Some options for the inspector
     * @returns
     */
    var inspectRender = function (name, options) {
        if (options === void 0) { options = {
            noHooks: false,
            logMode: 'summarized',
            throttleInterval: 1000,
            maxRenders: settings === null || settings === void 0 ? void 0 : settings.PerformanceInspector.maxRenders,
        }; }
        var blacklist = (settings === null || settings === void 0 ? void 0 : settings.PerformanceInspector.blacklist) || [];
        if (blacklist.some(function (item) { return name.startsWith(item); }))
            return;
        var logger = (0, GlobalContext_1.useGlobalContext)().logger;
        var noHooks = options.noHooks, logMode = options.logMode, throttleInterval = options.throttleInterval, maxRenders = options.maxRenders;
        if (!(settings === null || settings === void 0 ? void 0 : settings.PerformanceInspector.enable) ||
            !(settings === null || settings === void 0 ? void 0 : settings.Environment.IsDev)) {
            return;
        }
        if (!noHooks) {
            (0, utils_1.onMount)(function () {
                logger.log("Mounted -> ".concat(name));
            });
            (0, utils_1.useUnmount)(function () {
                logger.log("Unmounted -> ".concat(name));
            });
        }
        renderCounter[name] = renderCounter[name] ? renderCounter[name] + 1 : 1;
        var renders = renderCounter[name];
        if (renders > maxRenders) {
            renderCounter[name] = 0;
            throw new errors_1.PerformanceError('maxRenders', {
                name: name,
                throttleInterval: throttleInterval,
                maxRenders: maxRenders,
            });
        }
        if (logMode === 'raw') {
            logger.log("Rendered -> ".concat(name, ": ").concat(renders));
            return;
        }
        function logSummary() {
            if (renders <= 0)
                return;
            logger.log("Render summary -> ".concat(name, ": ").concat(renders));
            renderCounter[name] = 0;
        }
        (0, utils_1.throttle)(logSummary, name, throttleInterval);
    };
    return { inspectRender: inspectRender };
}
exports.makePerformanceInspector = makePerformanceInspector;
