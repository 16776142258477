"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.breakpointHooksFactory = exports.buildMediaQueries = void 0;
function buildMediaQueries(breakpoints) {
    function getBreakpoint(breakpoint) {
        return getBreakpointValue(breakpoint, breakpoints);
    }
    var queries = {
        up: function (test) {
            // Upwards of... (excluding)
            var min = getBreakpoint(test);
            return "@media screen and (minWidth:".concat(min, "px)");
        },
        down: function (test) {
            // Downwards of... (excluding)
            var max = getBreakpoint(test);
            return "@media screen and (maxWidth:".concat(max, "px)");
        },
        is: function (test) {
            // Is media... (exact)
            var value = getBreakpoint(test);
            return "@media screen and (minWidth:".concat(value, "px) and (maxWidth:").concat(value, "px)");
        },
        not: function (test) {
            // Is NOT media... (exact)
            var value = getBreakpoint(test);
            return "@media not screen and (minWidth:".concat(value, "px) and (maxWidth:").concat(value, "px)");
        },
    };
    var renderToPlatformQuery = function (props) {
        var mediaString = '';
        if (props === null || props === void 0 ? void 0 : props.is) {
            mediaString = queries.not(props.is);
        }
        else if (props === null || props === void 0 ? void 0 : props.not) {
            mediaString = queries.is(props.not);
        }
        else if (props === null || props === void 0 ? void 0 : props.up) {
            mediaString = queries.down(props.up);
        }
        else if (props === null || props === void 0 ? void 0 : props.down) {
            mediaString = queries.up(props.down);
        }
        return mediaString;
    };
    return __assign(__assign({}, queries), { renderToPlatformQuery: renderToPlatformQuery });
}
exports.buildMediaQueries = buildMediaQueries;
function getBreakpointValue(breakpoint, breakpoints) {
    if (breakpoints[breakpoint]) {
        return breakpoints[breakpoint];
    }
    return Infinity;
}
function breakpointHooksFactory(breakpoints, getCurrentSize) {
    function getBreakpoint(breakpoint) {
        return getBreakpointValue(breakpoint, breakpoints);
    }
    var hooks = {
        up: function (test) {
            // Upwards of... (excluding)
            var currentSize = getCurrentSize();
            var min = getBreakpoint(test);
            return currentSize[0] > min;
        },
        down: function (test) {
            // Downwards of... (excluding)
            var max = getBreakpoint(test);
            var currentSize = getCurrentSize();
            return currentSize[0] < max;
        },
        is: function (test) {
            // Is media... (exact)
            var value = getBreakpoint(test);
            var currentSize = getCurrentSize();
            return currentSize[0] === value;
        },
        not: function (test) {
            // Is NOT media... (exact)
            var value = getBreakpoint(test);
            var currentSize = getCurrentSize();
            return currentSize[0] !== value;
        },
    };
    var shouldRenderToPlatform = function (props) {
        var res = true;
        if (props === null || props === void 0 ? void 0 : props.is) {
            res = hooks.is(props.is);
        }
        else if (props === null || props === void 0 ? void 0 : props.not) {
            res = hooks.not(props.not);
        }
        else if (props === null || props === void 0 ? void 0 : props.up) {
            res = hooks.up(props.up);
        }
        else if (props === null || props === void 0 ? void 0 : props.down) {
            res = hooks.down(props.down);
        }
        return res;
    };
    return __assign(__assign({}, hooks), { shouldRenderToPlatform: shouldRenderToPlatform });
}
exports.breakpointHooksFactory = breakpointHooksFactory;
