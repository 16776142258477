import { customTextStyles } from './Text'
import { TagComposition } from '@codeleap/web'
import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'

const createTagVariant = createStyles<TagComposition>

export const TagStyles = {
  default: createTagVariant((theme) => {
    const DEFAULT_HEIGHT = 32
    const backgroundColor = theme.colors.primary1
    const color = theme.colors.neutral8
    const iconSize = theme.values.iconSize[2]

    return {
      wrapper: {
        width: 'fit-content',
        height: DEFAULT_HEIGHT,
        padding: theme.spacing.value(1),
        borderRadius: theme.borderRadius.tiny,
        backgroundColor,
        ...theme.presets.alignCenter,
        ...theme.presets.justifyCenter,
        ...theme.presets.row,
      },
      'wrapper:disabled': {
        cursor: 'not-allowed',
      },
      leftWrapper: {
        ...theme.presets.alignCenter,
        ...theme.presets.justifyCenter,
        ...theme.presets.row,
        marginRight: theme.spacing.value(0.5),
      },
      rightWrapper: {
        ...theme.presets.alignCenter,
        ...theme.presets.justifyCenter,
        ...theme.presets.row,
        marginLeft: theme.spacing.value(0.5),
      },
      text: {
        color,
        ...customTextStyles('p3'),
        lineHeight: 16,
        marginVertical: 'auto',
      },
      leftIcon: {
        color,
        height: iconSize,
        width: iconSize,
        marginRight: theme.spacing.value(0.5),
      },
      rightIcon: {
        color,
        height: iconSize,
        width: iconSize,
        marginLeft: theme.spacing.value(0.5),
      },
      rightBadgeWrapper: {
        ...theme.presets.relative,
        marginLeft: theme.spacing.value(0.5),
      },
      leftBadgeWrapper: {
        ...theme.presets.relative,
        marginRight: theme.spacing.value(0.5),
      },
    }
  }),
  neutral: createTagVariant((theme) => {
    const backgroundColor = theme.colors.neutral2
    const color = theme.colors.neutral8

    return {
      wrapper: { backgroundColor },
      text: { color },
      leftIcon: { color },
      rightIcon: { color },
    }
  }),
  destructive: createTagVariant((theme) => {
    const backgroundColor = theme.colors.destructive1
    return { wrapper: { backgroundColor } }
  }),
  'border:small': createTagVariant((theme) => ({
    wrapper: { borderRadius: theme.borderRadius.small },
  })),
  'border:medium': createTagVariant((theme) => ({
    wrapper: { borderRadius: theme.borderRadius.medium },
  })),
}

StyleRegistry.registerVariants('Tag', TagStyles)
