"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.dynamicVariants = exports.createDynamicVariants = exports.cursorTypes = exports.borderProperties = exports.borderDirection = exports.borderYDirection = exports.borderXDirection = exports.colorVariants = void 0;
var utils_1 = require("./utils");
exports.colorVariants = ['backgroundColor', 'color'];
exports.borderXDirection = ['left', 'right'];
exports.borderYDirection = ['bottom', 'top'];
exports.borderDirection = __spreadArray(__spreadArray(__spreadArray([], __read(exports.borderYDirection), false), __read(exports.borderXDirection), false), [''], false);
exports.borderProperties = ['color', 'radius', 'width'];
exports.cursorTypes = [
    'not-allowed',
    'help',
    'pointer',
    'wait',
    ''
];
var createDynamicVariants = function () {
    var dynamicVariants = {};
    function createVariant(variantName, variantReturn) {
        dynamicVariants[variantName] = variantReturn;
    }
    exports.colorVariants.forEach(function (variant) {
        createVariant(variant, function (theme, color) {
            var _a;
            return (_a = {},
                _a[variant] = theme['colors'][color],
                _a);
        });
    });
    exports.borderDirection.forEach(function (direction) {
        if (exports.borderYDirection.includes(direction)) {
            exports.borderXDirection.forEach(function (y) {
                var variant = "border".concat((0, utils_1.capitalize)(direction)).concat((0, utils_1.capitalize)(y), "Radius");
                createVariant(variant, function (theme, value) {
                    var _a;
                    return (_a = {},
                        _a[variant] = theme['borderRadius'][value],
                        _a);
                });
            });
        }
        exports.borderProperties.forEach(function (property) {
            var variant = "border".concat((0, utils_1.capitalize)(direction)).concat((0, utils_1.capitalize)(property));
            createVariant(variant, function (theme, value) {
                var _a;
                return (_a = {},
                    _a[variant] = property == 'color' ? theme['colors'][value] : theme['borderRadius'][value],
                    _a);
            });
        });
    });
    createVariant('cursor', function (theme, cursor) { return ({ cursor: cursor }); });
    createVariant('bg', function (theme, color) { return ({
        backgroundColor: theme['colors'][color]
    }); });
    createVariant('effect', function (theme, effect) { return theme['effects'][effect]; });
    createVariant('scale', function (theme, value) { return ({
        transform: typeof localStorage !== 'undefined' ? "scale(".concat(value, ")") : [{ 'scale': Number(value) }]
    }); });
    return dynamicVariants;
};
exports.createDynamicVariants = createDynamicVariants;
exports.dynamicVariants = (0, exports.createDynamicVariants)();
