import { OSAlert } from '@/config'
import { APIClient } from '@/services'
import { useI18N } from '@codeleap/common'
import { useSession } from './useSession'

export function useDeleteAccount() {
  const { logout, profile } = useSession()
  const { t } = useI18N()

  function onDeleteAccount() {
    OSAlert.ask({
      title: t('profile.deleteAccountModal.title1'),
      body: t('profile.deleteAccountModal.body1'),
      options: [
        { 
          text: t('profile.deleteAccountModal.confirm'),
          onPress: confirmDeleteAccount,
          style: 'destructive'
        },
        {
          text: t('profile.deleteAccountModal.cancel'),
          style: 'outline',
        },
      ]
    })
  }

  function confirmDeleteAccount() {
    OSAlert.ask({
      title: t('profile.deleteAccountModal.title2'),
      body: t('profile.deleteAccountModal.body2'),
      options: [
        { 
          text: t('profile.deleteAccountModal.delete'),
          onPress: deleteAccount,
          style: 'destructive'
        },
        {
          text: t('profile.deleteAccountModal.cancel'),
          style: 'outline',
        },
      ]
    })
  }

  const deleteAccount = async () => {
    logout()
    APIClient.Session.deleteProfile(profile?.id)
  }

  return {
    onDeleteAccount,
    deleteAccount,
    confirmDeleteAccount,
  }
}
