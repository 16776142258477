"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spacingShortVariants = exports.spacingVariants = void 0;
exports.spacingVariants = [
    'Vertical',
    'Horizontal',
    'Bottom',
    'Top',
    'Left',
    'Right',
    '',
];
exports.spacingShortVariants = [
    'y',
    'x',
    'b',
    't',
    'l',
    'r',
    '',
];
