import { createStyles } from '@codeleap/styles'
import { customTextStyles } from './Text'
import { ProgressBarComposition } from '@codeleap/web'
import { StyleRegistry } from '../styles'

const createProgressBarVariant = createStyles<ProgressBarComposition>

export const ProgressBarStyles = {
  default: createProgressBarVariant((theme) => ({
    wrapper: {
      ...theme.presets.flex,
      ...theme.presets.row,
      ...theme.presets.alignCenter,
      ...theme.spacing.gap(1),
    },
    progress: {
      ...theme.presets.flex,
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: theme.colors.neutral3,
      borderRadius: theme.borderRadius.rounded,
      transform: 'translateZ(0)',
      height: 8,
    },
    indicator: {
      backgroundColor: theme.colors.primary3,
      ...theme.presets.flex,
      ...theme.presets.fullHeight,
      transition: 'transform 600ms cubic-bezier(0.65, 0, 0.35, 1)',
    },
    text: {
      ...customTextStyles('p1'),
      color: theme.colors.neutral7,
    },
    icon: {
      width: theme.values.iconSize[2],
      height: theme.values.iconSize[2],
      color: theme.colors.neutral7,
    },
  })),
}

StyleRegistry.registerVariants('ProgressBar', ProgressBarStyles)
