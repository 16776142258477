"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useForm = exports.FormTypes = void 0;
var utils_1 = require("../../utils");
var react_1 = require("react");
var i18n_1 = require("../i18n");
var GlobalContext_1 = require("../../contexts/GlobalContext");
exports.FormTypes = __importStar(require("./types"));
var SCOPE = 'useForm';
var shouldLog = function (x, config) {
    return (config.log || []).includes(x);
};
function useForm(formParam, formConfig) {
    if (formConfig === void 0) { formConfig = {}; }
    var i18n = (0, i18n_1.useI18N)();
    var form = (0, utils_1.useMemo)(function () {
        return utils_1.TypeGuards.isFunction(formParam) ? formParam() : formParam;
    }, [formParam, i18n === null || i18n === void 0 ? void 0 : i18n.locale]);
    var config = __assign({ validateOn: 'change' }, formConfig);
    var getInitialState = (0, react_1.useCallback)(function () {
        return (0, utils_1.deepMerge)(form.defaultValue, config.initialState || {});
    }, [form.defaultValue, config.initialState]);
    var getInitialErrors = (0, react_1.useCallback)(function () {
        var errors = Object.keys(form.staticFieldProps).map(function (key) { return [key, '']; });
        return Object.fromEntries(errors);
    }, [form.staticFieldProps]);
    var _a = __read((0, utils_1.usePartialState)(getInitialState), 2), formValues = _a[0], setFormValues = _a[1];
    var _b = (0, GlobalContext_1.useGlobalContext)(), logger = _b.logger, isBrowser = _b.isBrowser;
    var _c = __read((0, utils_1.usePartialState)(getInitialErrors), 2), fieldErrors = _c[0], setFieldErrors = _c[1];
    // @ts-ignore
    function setFieldValue() {
        var _a;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // @ts-ignore
        var transform = ((_a = form === null || form === void 0 ? void 0 : form.staticFieldProps[args[0]]) === null || _a === void 0 ? void 0 : _a.transformer) || (function (x) { return x; });
        // @ts-ignore
        var val = (0, utils_1.deepSet)([args[0], transform(args[1])]);
        if (shouldLog('setValue', config) || shouldLog("setValue.".concat(args[0]), config)) {
            // @ts-ignore
            logger.log(
            // @ts-ignore
            "Set ".concat(form.name, "/").concat(args[0], " to ").concat(String(args[1])), '', SCOPE);
        }
        setFormValues(val);
    }
    function validateField(field, set, val) {
        if (set === void 0) { set = false; }
        // @ts-ignore
        var validate = form.staticFieldProps[field].validate;
        if (validate) {
            var value = val !== undefined ? val : (0, utils_1.deepGet)(field, formValues);
            // @ts-ignore
            var result_1 = validate(value, formValues);
            if (shouldLog('validate', config) || shouldLog("validate.".concat(field), config)) {
                logger.log("Validation for ".concat(form.name, " ").concat(field), { result: result_1, formValues: formValues, value: value }, SCOPE);
            }
            if (set) {
                setFieldErrors(function () {
                    var _a;
                    return (_a = {},
                        _a[field] = result_1.valid ? '' : result_1.message,
                        _a);
                });
            }
            return result_1;
        }
        return {
            valid: true,
            message: '',
        };
    }
    function validateMultiple(fields, set) {
        if (set === void 0) { set = false; }
        // @ts-ignore
        var results = fields.map(function (field) { return [field, validateField(field, set)]; });
        var overallValid = results.every(function (_a) {
            var _b = __read(_a, 2), result = _b[1];
            return result.valid;
        });
        var fieldResults = Object.fromEntries(results);
        return {
            valid: overallValid,
            results: fieldResults,
        };
    }
    function validateAll(set) {
        var e_1, _a;
        if (set === void 0) { set = false; }
        var errors = __assign({}, fieldErrors);
        try {
            for (var _b = __values(Object.entries(form.staticFieldProps)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 1), path = _d[0];
                var result = validateField(path);
                errors[path] = result.valid ? '' : result === null || result === void 0 ? void 0 : result.message;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (set) {
            setFieldErrors(errors);
        }
        return Object.values(errors).join('').length === 0;
    }
    var inputRefs = (0, react_1.useRef)([]);
    function focus(idx) {
        var _a, _b, _c;
        // if (!(idx < inputRefs.current.length)) return
        var nextRef = (_b = (_a = inputRefs === null || inputRefs === void 0 ? void 0 : inputRefs.current) === null || _a === void 0 ? void 0 : _a[idx]) === null || _b === void 0 ? void 0 : _b.current;
        if ((nextRef === null || nextRef === void 0 ? void 0 : nextRef.focus) && (nextRef === null || nextRef === void 0 ? void 0 : nextRef.isTextInput)) {
            (_c = nextRef.focus) === null || _c === void 0 ? void 0 : _c.call(nextRef);
        }
    }
    var registeredFields = (0, react_1.useRef)([]);
    var registeredTextRefsOnThisRender = 0;
    function register(field, transformProps) {
        if (transformProps === void 0) { transformProps = function (p) { return p; }; }
        var nFields = registeredFields.current.length;
        var _a = 
        // @ts-ignore
        form.staticFieldProps[field], changeEventName = _a.changeEventName, validate = _a.validate, type = _a.type, componentProps = _a.componentProps, staticProps = __rest(_a, ["changeEventName", "validate", "type", "componentProps"]);
        var dynamicProps = {
            value: (0, utils_1.deepGet)(field, formValues),
        };
        if (type === 'number') {
            dynamicProps.value = Number.isNaN(dynamicProps.value) ? '' : String(dynamicProps.value);
            if (isBrowser) {
                dynamicProps.type = 'number';
            }
            else {
                dynamicProps.keyboardType = 'numeric';
            }
        }
        if (changeEventName) {
            dynamicProps[changeEventName] = function (value) {
                if (type === 'number') {
                    value = Number(value);
                    if (typeof staticProps.precision !== 'undefined') {
                        value = Number(value.toFixed(staticProps.precision));
                    }
                }
                else if (type === 'file') {
                    value = utils_1.TypeGuards.isArray(value) && !!value.length ? value[0] : null;
                }
                // @ts-ignore
                setFieldValue(field, value);
            };
        }
        if (type === 'text' || type === 'number') {
            if (!isBrowser && !staticProps.multiline) {
                dynamicProps.returnKeyType = 'next';
            }
            var thisRefIdx_1 = registeredTextRefsOnThisRender;
            if (thisRefIdx_1 >= inputRefs.current.length) {
                inputRefs.current.push((0, react_1.createRef)());
            }
            dynamicProps.ref = inputRefs.current[thisRefIdx_1];
            registeredTextRefsOnThisRender++;
            if (!isBrowser && !staticProps.multiline) {
                dynamicProps.onSubmitEditing = function () {
                    var nextRef = thisRefIdx_1 + 1;
                    if (inputRefs.current.length <= nextRef)
                        return;
                    focus(nextRef);
                };
            }
        }
        registeredFields.current.push(type);
        var resolvedProps = __assign(__assign(__assign({}, staticProps), dynamicProps), { validate: function (v) {
                return validateField(field, true, v);
            } });
        var componentPropsConfig = utils_1.TypeGuards.isFunction(componentProps) ? componentProps(resolvedProps) : (componentProps || {});
        var t = transformProps(__assign(__assign(__assign({}, resolvedProps), componentProps), componentPropsConfig));
        return t;
    }
    function getTransformedValue() {
        return formValues;
    }
    function onSubmit(cb, e) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (e === null || e === void 0 ? void 0 : e.preventDefault)
                            e.preventDefault();
                        return [4 /*yield*/, cb(getTransformedValue())];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function reset(states) {
        var resetStates = states || ['values', 'errors'];
        if (resetStates.includes('values')) {
            setFormValues(getInitialState());
        }
        if (resetStates.includes('errors')) {
            setFieldErrors(getInitialErrors());
        }
    }
    var setters = {};
    var _loop_1 = function (fieldName) {
        // @ts-ignore
        setters[fieldName] = function (value) { return setFieldValue(fieldName, value); };
    };
    for (var fieldName in formValues) {
        _loop_1(fieldName);
    }
    return {
        setFieldValue: setFieldValue,
        values: formValues,
        register: register,
        validateAll: validateAll,
        validateField: validateField,
        onSubmit: onSubmit,
        fieldErrors: fieldErrors,
        getTransformedValue: getTransformedValue,
        setFormValues: setFormValues,
        reset: reset,
        validateMultiple: validateMultiple,
        isValid: validateAll(),
        setters: setters,
    };
}
exports.useForm = useForm;
