import React from 'react'
import phonesJSON from '@/config/i18n/phones.json'
import { TypeGuards } from '@codeleap/common'
import { LocalStorage } from '@/config'

const parsedOptions: Record<string, PhoneProps> = {}

function parsePhoneOption(phone: typeof phonesJSON[0]): PhoneProps {
  const key = `${phone?.name}-${phone?.mask}`

  const applyMask = (phone?.mask as string)?.replace?.(/0/g, '9')

  const data = {
    ...phone,
    mask: phone?.mask as string,
    value: key,
    label: `${phone?.name} (${phone?.iso})`,
    maskPlaceholder: `${phone?.mask}`,
    applyMask,
    description: `${phone?.name} (${phone?.iso})`,
  }

  return data
}

Object.entries(phonesJSON).forEach(([_key, value]) => {
  if (TypeGuards.isArray(value?.mask)) {
    const phone = parsePhoneOption({ ...value, mask: value?.mask[value?.mask?.length - 1] })

    parsedOptions[phone?.value] = phone

    return
  }

  const phone = parsePhoneOption(value)

  parsedOptions[phone?.value] = phone
})

const options = Object.values(parsedOptions)
export const phoneOptionsList = Object.values(parsedOptions)

export const phones = parsedOptions

export type PhoneProps = {
  applyMask: string
  name: string
  code: string
  iso: string
  flag: string
  mask: string
  label: string
  maskPlaceholder: string
  value: string
  description: string
}

export type UsePhoneOptions = {
  disableInternalization?: boolean
  defaultLocale?: string
  initialLocale?: string
}

const DEFAULT_LOCALE = 'GB'

export const usePhoneOptions = (props: UsePhoneOptions) => {
  const {
    defaultLocale = DEFAULT_LOCALE,
    initialLocale = null,
    disableInternalization = false,
  } = props

  const locale = LocalStorage.getItem('LOCALE')

  const localeArray = locale?.split?.('-')
  const localeIso = localeArray?.[localeArray?.length - 1]

  const iso = TypeGuards.isString(initialLocale)
    ? initialLocale
    : (localeIso ?? defaultLocale)

  const localePhone: PhoneProps = React.useMemo(() => {
    let phone = null

    if (disableInternalization) {
      phone = options?.filter?.(option => option?.iso === (initialLocale ?? defaultLocale))
    } else {
      phone = options?.filter?.(option => option?.iso === iso)
    }

    return phone?.[0] ?? options?.[0]
  }, [iso])

  return {
    phones,
    options,
    localePhone,
  }
}
