"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StylePersistor = void 0;
var minifier_1 = require("./minifier");
var StylePersistor = /** @class */ (function () {
    function StylePersistor(storage) {
        this.storage = storage;
    }
    StylePersistor.prototype.setItem = function (name, value) {
        return this.storage.set(name, value);
    };
    StylePersistor.prototype.getItem = function (name) {
        var _a;
        var persistedValue = (_a = this.storage) === null || _a === void 0 ? void 0 : _a.get(name);
        return minifier_1.minifier.decompress(persistedValue !== null && persistedValue !== void 0 ? persistedValue : null);
    };
    StylePersistor.prototype.removeItem = function (name) {
        return this.storage.del(name);
    };
    return StylePersistor;
}());
exports.StylePersistor = StylePersistor;
