"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.minifier = exports.decompress = exports.compress = void 0;
var lz_string_1 = require("lz-string");
function compress(value) {
    if (!value)
        return value;
    return (0, lz_string_1.compressToBase64)(JSON.stringify(value));
}
exports.compress = compress;
function decompress(value) {
    if (!value)
        return value;
    var decoded = (0, lz_string_1.decompressFromBase64)(value);
    return JSON.parse(decoded);
}
exports.decompress = decompress;
exports.minifier = {
    compress: compress,
    decompress: decompress,
};
