"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cache = void 0;
var zustand_1 = require("zustand");
var middleware_1 = require("zustand/middleware");
var constants_1 = require("./constants");
var hashKey_1 = require("./hashKey");
function getStaleTime() {
    var time = 7;
    var currentTime = new Date();
    currentTime.setDate(currentTime.getDate() + time);
    return currentTime;
}
var Cache = /** @class */ (function () {
    function Cache(registryName, storage, persistCache) {
        if (storage === void 0) { storage = null; }
        if (persistCache === void 0) { persistCache = !!storage; }
        this.registryName = registryName;
        this.storage = storage;
        this.persistCache = persistCache;
        this.cache = {};
        this.persistor = null;
        this.store = {
            cached: {},
            cacheFor: function () { return null; },
            reset: function () { return null; },
            staleTime: null
        };
        if (!persistCache)
            return;
        this.createPersistor(registryName);
        if (!constants_1.StyleConstants.STORE_CACHE_ENABLED || !this.persistor)
            return;
        var currentTime = new Date();
        var staleTime = new Date(this.store.staleTime);
        var isStaled = currentTime > staleTime;
        if (isStaled) {
            this.store.reset();
            return;
        }
        this.setCache(this.store.cached);
    }
    Cache.prototype.keyFor = function (cacheBaseKey, data) {
        var _a;
        var values = [cacheBaseKey, data];
        var cacheKey = (0, hashKey_1.hashKey)(values);
        var cachedValue = (_a = this.cache[cacheKey]) !== null && _a !== void 0 ? _a : null;
        return {
            key: cacheKey,
            value: cachedValue,
        };
    };
    Cache.prototype.cacheFor = function (key, cache) {
        this.cache[key] = cache;
        if (this.persistCache)
            this.store.cacheFor(key, cache);
        return cache;
    };
    Cache.prototype.setCache = function (cache) {
        this.cache = cache;
    };
    Cache.prototype.clear = function () {
        this.cache = {};
        if (this.persistCache)
            this.persistor.persist.clearStorage();
    };
    Cache.prototype.createPersistor = function (registryName) {
        var _this = this;
        if (!this.persistCache)
            return null;
        var persistor = (0, zustand_1.create)((0, middleware_1.persist)(function (set, get) { return ({
            staleTime: getStaleTime(),
            cached: {},
            cacheFor: function (key, value) { return set(function (store) {
                var cached = store.cached;
                cached[key] = value;
                return {
                    cached: cached
                };
            }); },
            reset: function () { return set({
                cached: {},
                staleTime: getStaleTime()
            }); }
        }); }, {
            name: "@styles.caches.".concat(registryName),
            version: constants_1.StyleConstants.STORES_PERSIST_VERSION,
            migrate: function (persistedState, version) {
                if (version != constants_1.StyleConstants.STORES_PERSIST_VERSION) {
                    persistedState.staleTime = getStaleTime();
                    persistedState.cached = {};
                    return persistedState;
                }
                return persistedState;
            },
            storage: (0, middleware_1.createJSONStorage)(function () { return _this.storage; }),
        }));
        this.persistor = persistor;
        this.store = persistor.getState();
        return persistor;
    };
    return Cache;
}());
exports.Cache = Cache;
