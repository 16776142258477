import { api, OSAlert } from '@/config'
import { TypeGuards } from '@codeleap/common'
import { Profile } from '@/types'
import { firebase } from '@/services/firebase'

const BASE_URL = 'profiles/'

export async function create(data: Partial<Profile>) {
  const { avatar, ...profileData } = data

  const requestData = {
    data: {
      ...profileData,
    },
  }

  if (!!avatar && !TypeGuards.isString(avatar)) {
    requestData['files'] = avatar
  }

  const response = await api.post<Profile>(`${BASE_URL}create/`, requestData,
    { multipart: true, debug: true },
  )

  return response.data
}

export async function isEmailAvailable(email: string) {
  const res = await api.get(`${BASE_URL}check_email/`, {
    params: {
      email,
    },
    validateStatus: () => true,
  })

  return res.status.toString().startsWith('2')

}

export async function update(profile: Partial<Profile>) {
  const { avatar, ...data } = profile

  const body: any = {
    data: {
      ...data,
    },
  }

  logger.debug('Update profile', body, 'Auth')

  const firebaseUser = firebase.auth.currentUser

  const response = await api.patch<Profile>(`${BASE_URL}${firebaseUser.uid}/`, {
    data: body.data,
    files: !!avatar ? avatar?.file : null,
  }, {
    multipart: true,
    debug: true,
  })

  return response.data
}

export async function retrieve() {
  const response = await api.get<Profile>(`${BASE_URL}i/`)

  return response.data
}

export async function requestPasswordReset(email: string) {
  const available = await isEmailAvailable(email)

  if (available) {
    OSAlert.error({
      body: 'Could not find an account matching the specified email address',
      title: 'Unknown email',
    })
  }

  try {
    await firebase.auth.sendPasswordResetEmail(email?.trim())
  } catch (e) {
    OSAlert.error({
      body: 'Error requesting reset.',
      title: 'Please try again',
    })

    logger.error(e)
  }
}

export async function deleteProfile(id: Profile['id']) {
  const response = await api.delete(`${BASE_URL}${id}/`)
  return response.data
}

export async function registerDevice(token: string) {
  const data = {
    registration_id: token,
    cloud_message_type: 'FCM',
    active: !!token,
  }

  await api.post('notifications/device/', data)

  logger.info('Register device', { data }, 'notifications')
}
