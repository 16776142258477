import { createStyles } from '@codeleap/styles'
import { StyleRegistry } from '../styles'
import { PaginationIndicatorComposition } from '@codeleap/web'
import { customTextStyles } from './Text'

const createPaginationIndicatorVariant = createStyles<PaginationIndicatorComposition>

export const PaginationIndicatorStyles = {
  default: createPaginationIndicatorVariant((theme) => ({
    loaderWrapper: {
      ...theme.presets.center,
      ...theme.presets.fullWidth,
    },
    text: {
      ...customTextStyles('h4'),
      ...theme.presets.textCenter,
      ...theme.presets.fullWidth,
      ...theme.spacing.paddingVertical(theme.values.innerSpacing.Y),
    },
  })),
}

StyleRegistry.registerVariants('PaginationIndicator', PaginationIndicatorStyles)
