"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.faker = void 0;
var names = [
    'James', 'John', 'Robert', 'Michael', 'William', 'David', 'Richard', 'Joseph', 'Thomas',
    'Charles', 'Christopher', 'Daniel', 'Matthew', 'Anthony', 'Donald', 'Mark', 'Paul', 'Steven',
    'Andrew', 'Kenneth', 'Joshua', 'Kevin', 'Brian', 'George', 'Edward', 'Ronald', 'Timothy',
    'Jason', 'Jeffrey', 'Ryan', 'Gary', 'Jacob'
];
var surnames = [
    'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis',
    'Lopez', 'Wilson', 'Anderson', 'Thomas', 'Taylor', 'Moore',
    'Jackson', 'Martin', 'Lee', 'Perez', 'Thompson', 'White', 'Harris', 'Sanchez', 'Clark',
    'Ramirez', 'Lewis', 'Robinson', 'Walker', 'Young', 'Allen', 'King', 'Wright', 'Scott', 'Torres',
];
var animals = [
    'lion', 'tiger', 'zebra', 'panda', 'koala', 'bear',
    'wolf', 'fox', 'rabbit', 'bat', 'spider', 'frog', 'shark'
];
function getRandom(list) {
    return list[Math.floor(Math.random() * list.length)];
}
function number(min, max) {
    if (min === void 0) { min = 0; }
    if (max === void 0) { max = 100; }
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
exports.faker = {
    lastName: function () { return getRandom(surnames); },
    firstName: function () { return getRandom(names); },
    animal: function () { return getRandom(animals); },
    number: number,
    name: function () { return "".concat(getRandom(names), " ").concat(getRandom(surnames)); }
};
