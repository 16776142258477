import { createStyles } from '@codeleap/styles'
import { PaginationButtonsComposition } from '@codeleap/web'
import { customTextStyles } from './Text'
import { StyleRegistry } from '../styles'

const createPaginationButtonVariant = createStyles<PaginationButtonsComposition>

export const PaginationButtonStyles = {
  default: createPaginationButtonVariant((theme) => {

    const defaultButtonStyles = {
      minWidth: theme.values.itemHeight.default,
      minHeight: theme.values.itemHeight.default,
      backgroundColor: theme.colors.neutral2,
      borderRadius: theme.borderRadius.tiny,
      '&:hover': {
        backgroundColor: theme.colors.primary4,
      },
      '&:hover > p': {
        color: theme.colors.neutral1,
      },
      [theme.media.down('tabletSmall')]: {
        minWidth: theme.values.itemHeight.tiny,
        minHeight: theme.values.itemHeight.tiny,
      },
    }

    return (
      {
        wrapper: {
          minHeight: 'auto',
          ...theme.spacing.gap(2),
          [theme.media.down('tabletSmall')]: {
            ...theme.spacing.gap(1),
          },
        },
        buttonWrapper: {
          ...defaultButtonStyles,
        },
        'buttonWrapper:selected': {
          backgroundColor: theme.colors.primary5,
          'p': {
            color: theme.colors.neutral1,
          },
        },
        buttonText: {
          color: theme.colors.primary5,
          '&:hover > p': {
            color: theme.colors.neutral1,
          },
          [theme.media.down('tabletSmall')]: {
            ...customTextStyles('p3'),
          },
        },
        arrowLeftButtonWrapper: {
          ...defaultButtonStyles,
        },
        arrowRightButtonWrapper: {
          ...defaultButtonStyles,
        },
      }
    )
  }),
}

StyleRegistry.registerVariants('PaginationButtons', PaginationButtonStyles)
