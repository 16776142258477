"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.hashKey = void 0;
var js_sha256_1 = require("js-sha256");
var styleKey = '@styles-version';
var version = (_a = require('../../package.json')) === null || _a === void 0 ? void 0 : _a.version;
var hashKey = function (value) {
    var _a;
    value.push((_a = {}, _a[styleKey] = version, _a));
    var str = JSON.stringify(value);
    return (0, js_sha256_1.sha256)(str);
};
exports.hashKey = hashKey;
