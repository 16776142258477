import React, { useContext } from 'react'
import { TypeGuards } from '@codeleap/common'
import { StyledProp } from '@codeleap/styles'
import { NavigationComposition } from '@/config/stylesheets/Navigation'
import { usePathname } from 'next/navigation'
import { navigation } from '@/config'
import { ParsedUrlQuery } from 'querystring'

type GetDynamicParamsProps<T extends string = string> = {
  defaultValues?: Partial<Record<T | undefined, string>>
}

type TNavigationContext = {
  url: string
  getDynamicParams: <T extends string = string>(listParams: Array<T>, props?: GetDynamicParamsProps<T>) => Record<T | undefined, string>
  style?: StyledProp<NavigationComposition>
  params?: ParsedUrlQuery
}

type NavigationContainerProps = {
  children?: React.ReactNode
  style?: StyledProp<NavigationComposition>
}

const NavigationContext = React.createContext({} as TNavigationContext)

export function NavigationContainer(props: NavigationContainerProps) {
  const {
    children,
    ...rest
  } = props

  const params = navigation?.context?.router?.query

  const pathname = usePathname()

  function getDynamicParams<T extends string = string>(listParams: Array<T>, props: GetDynamicParamsProps<T>): Record<T | undefined, string> {
    const {
      defaultValues = {},
    } = props

    const parseParams = (arr: string[]) => {
      let result = {}

      listParams?.forEach((name, i) => {
        const value = arr?.[i] as string

        const isNil = TypeGuards.isNil(value) || value?.length <= 0

        const defaultValue = defaultValues?.[name as any] ?? null

        result = {
          ...result,
          [name]: isNil ? defaultValue : value,
        }
      })

      return result as any
    }

    if (!TypeGuards.isNil(params)) {
      const isDynamicParams = Object.keys(params)?.includes('')

      const _params = isDynamicParams ? params[''] : params

      return parseParams(Object.values(_params as unknown as string[]))
    }

    return parseParams([])
  }

  const value: TNavigationContext = {
    ...rest,
    url: pathname,
    getDynamicParams,
    params,
  }

  return <NavigationContext.Provider value={value}>
    {children}
  </NavigationContext.Provider>
}

export const useNavigation = () => {
  const ctx = useContext(NavigationContext)

  if (!ctx) {
    throw new Error('NavigationContext not found')
  }

  return ctx
}
