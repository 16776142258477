"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryManager = void 0;
/* eslint-disable max-lines */
var react_query_1 = require("@tanstack/react-query");
var react_1 = require("react");
var __1 = require("../..");
var typeGuards_1 = require("../../utils/typeGuards");
__exportStar(require("./types"), exports);
var QueryManager = /** @class */ (function () {
    function QueryManager(options) {
        var _this = this;
        this.queryStates = {};
        this.addItem = function (args) { return __awaiter(_this, void 0, void 0, function () {
            var updateOnList, promises;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updateOnList = __1.TypeGuards.isUndefined(args === null || args === void 0 ? void 0 : args.onListsWithFilters) ? undefined : (0, react_query_1.hashQueryKey)(this.filteredQueryKey(args.onListsWithFilters));
                        promises = Object.entries(this.queryStates).map(function (_a) {
                            var _b = __read(_a, 2), hashedKey = _b[0], key = _b[1].key;
                            return __awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return __generator(this, function (_c) {
                                    if (!__1.TypeGuards.isUndefined(updateOnList)) {
                                        if (updateOnList !== hashedKey) {
                                            return [2 /*return*/];
                                        }
                                    }
                                    this.queryClient.setQueryData(key, function (old) {
                                        var _a, _b, _c;
                                        var _d, _e, _f, _g, _h;
                                        if (!((_d = old === null || old === void 0 ? void 0 : old.pages) === null || _d === void 0 ? void 0 : _d.length) || (((_e = old === null || old === void 0 ? void 0 : old.pages) === null || _e === void 0 ? void 0 : _e.length) > 1 && ((_f = old === null || old === void 0 ? void 0 : old.pages) === null || _f === void 0 ? void 0 : _f.every(function (page) { return page.results.length <= 0; })))) {
                                            old = {
                                                pageParams: [],
                                                pages: [
                                                    {
                                                        results: [],
                                                        count: 0,
                                                        next: null,
                                                        previous: null,
                                                    },
                                                ],
                                            };
                                        }
                                        var itemsToAppend = (0, typeGuards_1.isArray)(args.item) ? args.item : [args.item];
                                        if (args.to === 'end') {
                                            var idx = old.pages.length - 1;
                                            (_a = old.pages[idx].results).push.apply(_a, __spreadArray([], __read(itemsToAppend), false));
                                            old.pages[idx].count += itemsToAppend.length;
                                            if (old.pageParams[idx]) {
                                                // @ts-ignore
                                                old.pageParams[idx].limit += itemsToAppend.length;
                                            }
                                            else {
                                                old.pageParams[idx] = {
                                                    limit: (_h = (_g = _this.options) === null || _g === void 0 ? void 0 : _g.limit) !== null && _h !== void 0 ? _h : itemsToAppend.length,
                                                    offset: 0,
                                                };
                                            }
                                        }
                                        else if (args.to === 'start') {
                                            (_b = old.pages[0].results).unshift.apply(_b, __spreadArray([], __read(itemsToAppend), false));
                                            // @ts-ignore
                                            if (old.pageParams[0]) {
                                                // @ts-ignore
                                                old.pageParams[0].offset -= itemsToAppend.length;
                                                // @ts-ignore
                                                old.pageParams[0].limit += itemsToAppend.length;
                                            }
                                            else {
                                                old.pageParams[0] = {
                                                    limit: itemsToAppend.length,
                                                    offset: -itemsToAppend.length,
                                                };
                                            }
                                        }
                                        else if (!!args.to) {
                                            var appendTo = (0, typeGuards_1.isArray)(args.to) ? args.to : args.to[hashedKey];
                                            var _j = __read(appendTo, 2), pageIdx = _j[0], itemIdx = _j[1];
                                            (_c = old.pages[pageIdx].results).splice.apply(_c, __spreadArray([itemIdx, 0], __read(itemsToAppend), false));
                                            if (old.pageParams[pageIdx]) {
                                                // @ts-ignore
                                                old.pageParams[pageIdx].offset -= itemsToAppend.length;
                                                // @ts-ignore
                                                old.pageParams[pageIdx].limit += itemsToAppend.length;
                                            }
                                            else {
                                                old.pageParams[pageIdx] = {
                                                    limit: itemsToAppend.length,
                                                    offset: -itemsToAppend.length,
                                                };
                                            }
                                        }
                                        return old;
                                    });
                                    return [2 /*return*/];
                                });
                            });
                        });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        this.options = options;
        this.itemMap = {};
        this.meta = options === null || options === void 0 ? void 0 : options.initialMeta;
        this.optionListeners = [];
    }
    QueryManager.prototype.extractKey = function (item) {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.options) === null || _a === void 0 ? void 0 : _a.keyExtractor) === null || _b === void 0 ? void 0 : _b.call(_a, item)) !== null && _c !== void 0 ? _c : item.id;
    };
    Object.defineProperty(QueryManager.prototype, "keySuffixes", {
        get: function () {
            return {
                list: 'list',
                infiniteList: 'infinite-list',
                create: 'create',
                update: 'update',
                delete: 'delete',
                retrieve: 'retrieve',
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QueryManager.prototype, "actions", {
        get: function () {
            var _this = this;
            var _a;
            var actions = (_a = this.options.actions) !== null && _a !== void 0 ? _a : {};
            var actionKeys = Object.keys(actions);
            var actionFunctions = actionKeys.reduce(function (acc, key) {
                var action = actions[key];
                // @ts-ignore
                acc[key] = function () {
                    var args = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        args[_i] = arguments[_i];
                    }
                    return action.apply(void 0, __spreadArray([_this], __read(args), false));
                };
                return acc;
            }, {});
            return actionFunctions;
        },
        enumerable: false,
        configurable: true
    });
    QueryManager.prototype.generateId = function () {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.options).generateId) === null || _b === void 0 ? void 0 : _b.call(_a)) !== null && _c !== void 0 ? _c : Date.now().toString();
    };
    QueryManager.prototype.useOptions = function (cb) {
        var _this = this;
        var _a = __read((0, react_1.useState)(this.options), 2), options = _a[0], setOptions = _a[1];
        var _b = __read((0, react_1.useState)(this.meta), 2), meta = _b[0], setMeta = _b[1];
        (0, react_1.useEffect)(function () {
            var idx = _this.optionListeners.push(function (o, meta) {
                setOptions(o);
                setMeta(meta);
                cb === null || cb === void 0 ? void 0 : cb(o, meta);
            }) - 1;
            return function () {
                _this.optionListeners.splice(idx, 1);
            };
        });
        return [options, meta];
    };
    QueryManager.prototype.updateItems = function (items) {
        return __awaiter(this, void 0, void 0, function () {
            var itemArr, ids, promises;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        itemArr = Array.isArray(items) ? items : [items];
                        ids = itemArr.map(function (i) {
                            var id = _this.extractKey(i);
                            _this.itemMap[id] = i;
                            _this.queryClient.setQueryData(_this.queryKeyFor(id), function (old) {
                                return i;
                            });
                            return id;
                        });
                        promises = Object.values(this.queryStates).map(function (_a) {
                            var key = _a.key, pagesById = _a.pagesById;
                            return __awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                return __generator(this, function (_b) {
                                    this.queryClient.setQueryData(key, function (old) {
                                        if (!old)
                                            return old;
                                        ids.forEach(function (id) {
                                            if (!pagesById[id])
                                                return;
                                            var _a = __read(pagesById[id], 2), pageIdx = _a[0], itemIdx = _a[1];
                                            old.pages[pageIdx].results[itemIdx] = _this.itemMap[id];
                                        });
                                        return old;
                                    });
                                    return [2 /*return*/];
                                });
                            });
                        });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    QueryManager.prototype.getItem = function (itemId, options) {
        return __awaiter(this, void 0, void 0, function () {
            var i, item;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        i = this.itemMap[itemId];
                        if (!((!i && !!options.fetchOnNotFoud) || options.forceRefetch)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.options.retrieveItem(itemId)];
                    case 1:
                        item = _a.sent();
                        this.updateItems(item);
                        return [2 /*return*/, item];
                    case 2: return [2 /*return*/, i];
                }
            });
        });
    };
    QueryManager.prototype.removeItem = function (itemId) {
        return __awaiter(this, void 0, void 0, function () {
            var removedPositions, promises;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        removedPositions = {};
                        promises = Object.entries(this.queryStates).map(function (_a) {
                            var _b = __read(_a, 2), hashedKey = _b[0], _c = _b[1], key = _c.key, pagesById = _c.pagesById;
                            return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_d) {
                                    this.queryClient.setQueryData(key, function (old) {
                                        var _a = __read(pagesById[itemId], 2), itemPage = _a[0], itemIdx = _a[1];
                                        old.pages[itemPage].results.splice(itemIdx, 1);
                                        // @ts-ignore
                                        old.pageParams[itemPage].limit -= 1;
                                        removedPositions[hashedKey] = [itemPage, itemIdx];
                                        return old;
                                    });
                                    return [2 /*return*/];
                                });
                            });
                        });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, removedPositions];
                }
            });
        });
    };
    QueryManager.prototype.transformData = function (data, key) {
        var e_1, _a;
        var _this = this;
        var _b, _c, _d;
        var pagesById = {};
        var flatItems = [];
        var itemIndexes = {};
        var hashedKey = (0, react_query_1.hashQueryKey)(key);
        var pageIdx = 0;
        try {
            for (var _e = __values((_b = data === null || data === void 0 ? void 0 : data.pages) !== null && _b !== void 0 ? _b : []), _f = _e.next(); !_f.done; _f = _e.next()) {
                var page = _f.value;
                page.results.forEach(function (i, itemIdx) {
                    var flatIdx = flatItems.length;
                    var itemId = i.id;
                    var include = true;
                    if (include) {
                        flatItems.push(i);
                    }
                    pagesById[itemId] = [pageIdx, itemIdx];
                    _this.itemMap[itemId] = i;
                    itemIndexes[itemId] = flatIdx;
                });
                pageIdx += 1;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.queryStates[hashedKey] = {
            itemIndexes: __assign(__assign({}, (_c = this.queryStates[hashedKey]) === null || _c === void 0 ? void 0 : _c.itemIndexes), itemIndexes),
            pagesById: __assign(__assign({}, (_d = this.queryStates[hashedKey]) === null || _d === void 0 ? void 0 : _d.pagesById), pagesById),
            key: key,
        };
        return {
            itemMap: this.itemMap,
            pagesById: pagesById,
            itemIndexes: itemIndexes,
            itemList: flatItems,
        };
    };
    Object.defineProperty(QueryManager.prototype, "queryKeys", {
        get: function () {
            return {
                list: [this.name, this.keySuffixes.list],
                // infiniteList: [this.name, this.keySuffixes.infiniteList],
                create: [this.name, this.keySuffixes.create],
                update: [this.name, this.keySuffixes.update],
                delete: [this.name, this.keySuffixes.delete],
                retrieve: [this.name, this.keySuffixes.retrieve],
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QueryManager.prototype, "name", {
        get: function () {
            return this.options.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QueryManager.prototype, "standardLimit", {
        get: function () {
            var _a;
            return (_a = this.options.limit) !== null && _a !== void 0 ? _a : 10;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(QueryManager.prototype, "queryClient", {
        get: function () {
            return this.options.queryClient;
        },
        enumerable: false,
        configurable: true
    });
    QueryManager.prototype.queryKeyFor = function (itemId) {
        return [this.name, this.keySuffixes.retrieve, itemId];
    };
    QueryManager.prototype.filteredQueryKey = function (filters) {
        return __spreadArray(__spreadArray([], __read(this.queryKeys.list), false), [filters], false);
    };
    QueryManager.prototype.useList = function (options) {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g;
        if (options === void 0) { options = {}; }
        var _h = __read((0, react_1.useState)(false), 2), isRefreshing = _h[0], setRefreshing = _h[1];
        var _j = options.filter, filter = _j === void 0 ? {} : _j, queryOptions = options.queryOptions;
        var queryKey = this.filteredQueryKey(filter);
        var hashedKey = (0, react_query_1.hashQueryKey)(queryKey);
        var useListEffect = (_b = (_a = this.options) === null || _a === void 0 ? void 0 : _a.useListEffect) !== null && _b !== void 0 ? _b : (function () { return null; });
        var query = (0, react_query_1.useInfiniteQuery)(__assign(__assign({}, queryOptions), { queryKey: queryKey, queryFn: function (query) { return __awaiter(_this, void 0, void 0, function () {
                var _a, _b;
                return __generator(this, function (_c) {
                    return [2 /*return*/, this.options.listItems(this.standardLimit, (_b = (_a = query.pageParam) === null || _a === void 0 ? void 0 : _a.offset) !== null && _b !== void 0 ? _b : 0, filter)];
                });
            }); }, refetchOnMount: function (query) {
                if (__1.TypeGuards.isBoolean(queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.refetchOnMount) || __1.TypeGuards.isString(queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.refetchOnMount)) {
                    return queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.refetchOnMount;
                }
                return query.state.dataUpdateCount === 0 || query.isStaleByTime();
            }, getNextPageParam: function (lastPage, pages) {
                var currentTotal = pages.reduce(function (acc, p) { return p.results.length + acc; }, 0);
                if (currentTotal >= ((lastPage === null || lastPage === void 0 ? void 0 : lastPage.count) || Infinity)) {
                    return undefined;
                }
                return {
                    limit: _this.standardLimit,
                    offset: currentTotal,
                };
            }, getPreviousPageParam: function (lastPage, pages) {
                var currentTotal = pages.reduce(function (acc, p) { return p.results.length + acc; }, 0);
                if (currentTotal >= ((lastPage === null || lastPage === void 0 ? void 0 : lastPage.count) || Infinity)) {
                    return undefined;
                }
                return {
                    limit: _this.standardLimit,
                    offset: currentTotal,
                };
            }, select: function (data) {
                var _a;
                var itemList = _this.transformData(data, queryKey).itemList;
                return {
                    pageParams: data.pageParams,
                    pages: (_a = data === null || data === void 0 ? void 0 : data.pages) !== null && _a !== void 0 ? _a : [],
                    flatItems: itemList,
                };
            }, keepPreviousData: true }));
        var refresh = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setRefreshing(true);
                        return [4 /*yield*/, this.refresh(filter)];
                    case 1:
                        _a.sent();
                        setRefreshing(false);
                        return [2 /*return*/];
                }
            });
        }); };
        var listEffect = useListEffect({
            query: query,
            refreshQuery: function (silent) {
                if (silent === void 0) { silent = true; }
                return silent ? _this.refresh(filter) : refresh();
            },
            cancelQuery: function () { return _this.queryClient.cancelQueries({ queryKey: queryKey, exact: true }); },
        });
        // @ts-ignore
        var items = (_c = query.data) === null || _c === void 0 ? void 0 : _c.flatItems;
        return {
            items: items,
            query: query,
            getNextPage: query.fetchNextPage,
            getPreviousPage: query.fetchPreviousPage,
            refresh: refresh,
            isRefreshing: isRefreshing,
            itemMap: this.itemMap,
            pagesById: (_e = (_d = this.queryStates[hashedKey]) === null || _d === void 0 ? void 0 : _d.pagesById) !== null && _e !== void 0 ? _e : {},
            itemIndexes: (_g = (_f = this.queryStates[hashedKey]) === null || _f === void 0 ? void 0 : _f.itemIndexes) !== null && _g !== void 0 ? _g : {},
        };
    };
    QueryManager.prototype.useRetrieve = function (options) {
        var _this = this;
        var _a = __read((0, react_1.useState)(false), 2), isRefreshing = _a[0], setRefreshing = _a[1];
        var itemId = options === null || options === void 0 ? void 0 : options.id;
        var query = (0, react_query_1.useQuery)(__assign(__assign({}, options === null || options === void 0 ? void 0 : options.queryOptions), { queryKey: this.queryKeyFor(itemId), initialData: function () {
                return _this.itemMap[itemId];
            }, queryFn: function () {
                return _this.options.retrieveItem(itemId);
            }, onSuccess: function (data) {
                _this.updateItems(data);
            } }));
        var refresh = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setRefreshing(true);
                        return [4 /*yield*/, this.refreshItem(itemId)];
                    case 1:
                        _a.sent();
                        setRefreshing(false);
                        return [2 /*return*/];
                }
            });
        }); };
        return {
            data: query.data,
            query: query,
            refresh: refresh,
            isRefreshing: isRefreshing,
        };
    };
    QueryManager.prototype.useItem = function (options) {
        return this.useRetrieve(options);
    };
    QueryManager.prototype.useCreate = function (options) {
        var _this = this;
        var _a;
        var _b = __read(this.useOptions(), 2), managerOptions = _b[0], meta = _b[1];
        var tmpOptions = (0, react_1.useRef)((_a = options !== null && options !== void 0 ? options : managerOptions.creation) !== null && _a !== void 0 ? _a : {
            appendTo: 'start',
            optimistic: false,
        });
        var getOptimisticItem = (0, __1.usePromise)({
            timeout: 1200,
        });
        var query = (0, react_query_1.useMutation)(__assign(__assign({}, options === null || options === void 0 ? void 0 : options.mutationOptions), { mutationFn: function (data) {
                return _this.options.createItem(data);
            }, mutationKey: this.queryKeys.create, onMutate: function (data) { return __awaiter(_this, void 0, void 0, function () {
                var addedItem, addedId;
                var _a, _b, _c, _d, _e, _f;
                return __generator(this, function (_g) {
                    switch (_g.label) {
                        case 0:
                            (_b = (_a = options === null || options === void 0 ? void 0 : options.mutationOptions) === null || _a === void 0 ? void 0 : _a.onMutate) === null || _b === void 0 ? void 0 : _b.call(_a, data);
                            if (!!!((_c = tmpOptions === null || tmpOptions === void 0 ? void 0 : tmpOptions.current) === null || _c === void 0 ? void 0 : _c.optimistic)) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.queryClient.cancelQueries({ queryKey: this.queryKeys.list })];
                        case 1:
                            _g.sent();
                            addedItem = __assign({ id: this.generateId() }, data);
                            getOptimisticItem.resolve(addedItem);
                            addedId = this.extractKey(addedItem);
                            this.addItem({
                                item: addedItem,
                                to: ((_d = tmpOptions === null || tmpOptions === void 0 ? void 0 : tmpOptions.current) === null || _d === void 0 ? void 0 : _d.appendTo) || ((_e = managerOptions.creation) === null || _e === void 0 ? void 0 : _e.appendTo) || 'start',
                                onListsWithFilters: (_f = tmpOptions.current) === null || _f === void 0 ? void 0 : _f.onListsWithFilters,
                            });
                            return [2 /*return*/, {
                                    // previousData,
                                    addedId: addedId,
                                }];
                        case 2: return [2 /*return*/];
                    }
                });
            }); }, onError: function (error, data, ctx) {
                var _a;
                var isOptimistic = (_a = tmpOptions.current) === null || _a === void 0 ? void 0 : _a.optimistic;
                if (isOptimistic) {
                    _this.removeItem(ctx.addedId);
                }
            }, onSuccess: function (data) {
                var _a, _b, _c, _d;
                if (!((_a = tmpOptions.current) === null || _a === void 0 ? void 0 : _a.optimistic)) {
                    _this.addItem({
                        item: data,
                        to: ((_b = tmpOptions === null || tmpOptions === void 0 ? void 0 : tmpOptions.current) === null || _b === void 0 ? void 0 : _b.appendTo) || ((_c = managerOptions.creation) === null || _c === void 0 ? void 0 : _c.appendTo) || 'start',
                        onListsWithFilters: (_d = tmpOptions === null || tmpOptions === void 0 ? void 0 : tmpOptions.current) === null || _d === void 0 ? void 0 : _d.onListsWithFilters,
                    });
                }
                else {
                    _this.updateItems(data);
                }
            } }));
        var createItem = function (data, options) { return __awaiter(_this, void 0, void 0, function () {
            var prevOptions, res;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        prevOptions = __assign({}, ((_a = tmpOptions.current) !== null && _a !== void 0 ? _a : {}));
                        if (!!options) {
                            tmpOptions.current = options;
                        }
                        res = null;
                        if (!((_b = tmpOptions.current) === null || _b === void 0 ? void 0 : _b.optimistic)) return [3 /*break*/, 2];
                        query.mutateAsync(data);
                        return [4 /*yield*/, getOptimisticItem._await()];
                    case 1:
                        res = _c.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, query.mutateAsync(data)];
                    case 3:
                        res = _c.sent();
                        _c.label = 4;
                    case 4:
                        if (!!options) {
                            tmpOptions.current = prevOptions;
                        }
                        return [2 /*return*/, res];
                }
            });
        }); };
        return {
            item: query.data,
            create: createItem,
            query: query,
        };
    };
    QueryManager.prototype.useUpdate = function (options) {
        var _this = this;
        var _a;
        var _b = __read(this.useOptions(), 1), managerOptions = _b[0];
        var tmpOptions = (0, react_1.useRef)((_a = options !== null && options !== void 0 ? options : managerOptions.update) !== null && _a !== void 0 ? _a : {
            optimistic: false,
        });
        var getOptimisticItem = (0, __1.usePromise)({
            timeout: 1200,
        });
        var query = (0, react_query_1.useMutation)(__assign(__assign({}, options === null || options === void 0 ? void 0 : options.mutationOptions), { mutationKey: this.queryKeys.update, onMutate: function (data) { return __awaiter(_this, void 0, void 0, function () {
                var prevItem, optimisticItem;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            (_b = (_a = options === null || options === void 0 ? void 0 : options.mutationOptions) === null || _a === void 0 ? void 0 : _a.onMutate) === null || _b === void 0 ? void 0 : _b.call(_a, data);
                            if (!((_c = tmpOptions.current) === null || _c === void 0 ? void 0 : _c.optimistic)) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.getItem(data.id, {
                                    fetchOnNotFoud: false,
                                })];
                        case 1:
                            prevItem = _d.sent();
                            if (!prevItem)
                                return [2 /*return*/];
                            optimisticItem = __assign(__assign({}, prevItem), data);
                            getOptimisticItem.resolve(optimisticItem);
                            this.updateItems(optimisticItem);
                            return [2 /*return*/, {
                                    previousItem: prevItem,
                                    optimisticItem: optimisticItem,
                                }];
                        case 2: return [2 /*return*/];
                    }
                });
            }); }, onError: function (error, data, ctx) {
                var _a, _b;
                if (((_a = tmpOptions.current) === null || _a === void 0 ? void 0 : _a.optimistic) && !!((_b = ctx === null || ctx === void 0 ? void 0 : ctx.previousItem) === null || _b === void 0 ? void 0 : _b.id)) {
                    _this.updateItems(ctx.previousItem);
                }
            }, mutationFn: function (data) {
                return _this.options.updateItem(data);
            }, onSuccess: function (data) {
                _this.updateItems(data);
            } }));
        var update = function (data, options) { return __awaiter(_this, void 0, void 0, function () {
            var prevOptions, res;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        prevOptions = tmpOptions.current;
                        if (!!options) {
                            tmpOptions.current = options;
                        }
                        res = null;
                        if (!((_a = tmpOptions.current) === null || _a === void 0 ? void 0 : _a.optimistic)) return [3 /*break*/, 2];
                        query.mutateAsync(data);
                        return [4 /*yield*/, getOptimisticItem._await()];
                    case 1:
                        res = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, query.mutateAsync(data)];
                    case 3:
                        res = _b.sent();
                        _b.label = 4;
                    case 4:
                        if (!!options) {
                            tmpOptions.current = prevOptions;
                        }
                        return [2 /*return*/, res];
                }
            });
        }); };
        return {
            update: update,
            query: query,
            item: query.data,
        };
    };
    QueryManager.prototype.useDelete = function (options) {
        var _this = this;
        var _a;
        var _b = __read(this.useOptions(), 1), managerOptions = _b[0];
        var tmpOptions = (0, react_1.useRef)((_a = options !== null && options !== void 0 ? options : managerOptions === null || managerOptions === void 0 ? void 0 : managerOptions.deletion) !== null && _a !== void 0 ? _a : {
            optimistic: false,
        });
        var getOptimisticItem = (0, __1.usePromise)({
            timeout: 1200,
        });
        var query = (0, react_query_1.useMutation)(__assign(__assign({}, options === null || options === void 0 ? void 0 : options.mutationOptions), { mutationKey: this.queryKeys.delete, onMutate: function (data) { return __awaiter(_this, void 0, void 0, function () {
                var prevItem, removedAt;
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            (_b = (_a = options === null || options === void 0 ? void 0 : options.mutationOptions) === null || _a === void 0 ? void 0 : _a.onMutate) === null || _b === void 0 ? void 0 : _b.call(_a, data);
                            if (!((_c = tmpOptions.current) === null || _c === void 0 ? void 0 : _c.optimistic)) return [3 /*break*/, 3];
                            return [4 /*yield*/, this.getItem(data.id, {
                                    fetchOnNotFoud: false,
                                })];
                        case 1:
                            prevItem = _d.sent();
                            getOptimisticItem.resolve(prevItem);
                            return [4 /*yield*/, this.removeItem(data.id)];
                        case 2:
                            removedAt = _d.sent();
                            if (!prevItem)
                                return [2 /*return*/];
                            return [2 /*return*/, {
                                    previousItem: prevItem,
                                    prevItemPages: removedAt,
                                }];
                        case 3: return [2 /*return*/];
                    }
                });
            }); }, mutationFn: function (data) {
                return _this.options.deleteItem(data);
            }, onError: function (error, data, ctx) {
                var _a, _b;
                if (!!((_a = ctx === null || ctx === void 0 ? void 0 : ctx.previousItem) === null || _a === void 0 ? void 0 : _a.id) && ((_b = tmpOptions.current) === null || _b === void 0 ? void 0 : _b.optimistic)) {
                    _this.addItem({
                        item: ctx.previousItem,
                        to: ctx.prevItemPages,
                    });
                }
            }, onSuccess: function (data) {
                var _a;
                if (!((_a = tmpOptions.current) === null || _a === void 0 ? void 0 : _a.optimistic)) {
                    _this.removeItem(data.id);
                }
            } }));
        var _delete = function (data, options) { return __awaiter(_this, void 0, void 0, function () {
            var prevOptions, prevItem;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        prevOptions = tmpOptions.current;
                        if (!!options) {
                            tmpOptions.current = options;
                        }
                        prevItem = null;
                        if (!((_a = tmpOptions.current) === null || _a === void 0 ? void 0 : _a.optimistic)) return [3 /*break*/, 2];
                        query.mutateAsync(data);
                        return [4 /*yield*/, getOptimisticItem._await()];
                    case 1:
                        prevItem = _b.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, query.mutateAsync(data)];
                    case 3:
                        prevItem = _b.sent();
                        _b.label = 4;
                    case 4:
                        if (!!options) {
                            tmpOptions.current = prevOptions;
                        }
                        return [2 /*return*/, prevItem];
                }
            });
        }); };
        return {
            delete: _delete,
            query: query,
        };
    };
    QueryManager.prototype.refreshItem = function (itemId) {
        return __awaiter(this, void 0, void 0, function () {
            var newItem;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getItem(itemId, {
                            fetchOnNotFoud: true,
                            forceRefetch: true,
                        })];
                    case 1:
                        newItem = _a.sent();
                        this.queryClient.setQueryData(this.queryKeyFor(itemId), function (old) {
                            return newItem;
                        });
                        this.updateItems(newItem);
                        return [2 /*return*/, newItem];
                }
            });
        });
    };
    QueryManager.prototype.refresh = function (filters) {
        return __awaiter(this, void 0, void 0, function () {
            var key;
            return __generator(this, function (_a) {
                if (!!filters) {
                    key = this.filteredQueryKey(filters);
                    this.queryClient.removeQueries(key);
                    this.queryClient.invalidateQueries(this.queryKeys.list);
                }
                else {
                    this.queryClient.removeQueries(this.queryKeys.list);
                }
                return [2 /*return*/];
            });
        });
    };
    QueryManager.prototype.setItem = function (item) {
        return this.updateItems(item);
    };
    QueryManager.prototype.use = function (options) {
        var _a;
        var list = this.useList({
            filter: options === null || options === void 0 ? void 0 : options.filter,
            queryOptions: (_a = options === null || options === void 0 ? void 0 : options.listOptions) === null || _a === void 0 ? void 0 : _a.queryOptions,
        });
        var create = this.useCreate(options === null || options === void 0 ? void 0 : options.creation);
        var update = this.useUpdate(options === null || options === void 0 ? void 0 : options.update);
        var del = this.useDelete(options === null || options === void 0 ? void 0 : options.deletion);
        var queries = {
            create: create,
            update: update,
            del: del,
        };
        return {
            items: list.items,
            list: list,
            itemMap: list.itemMap,
            create: create.create,
            update: update.update,
            delete: del.delete,
            getNextPage: list.getNextPage,
            getPreviousPage: list.getPreviousPage,
            refreshItem: this.refreshItem.bind(this),
            setItem: this.setItem.bind(this),
            refresh: list.refresh,
            isRefreshing: list.isRefreshing,
            actions: this.actions,
            updatedAt: list.query.dataUpdatedAt,
            queries: queries,
        };
    };
    QueryManager.prototype.setOptions = function (to) {
        var _this = this;
        var _a = this.options, _b = _a.creation, creation = _b === void 0 ? {} : _b, _c = _a.update, update = _c === void 0 ? {} : _c, _d = _a.deletion, deletion = _d === void 0 ? {} : _d, limit = _a.limit;
        var currentOptions = {
            creation: creation,
            update: update,
            deletion: deletion,
            limit: limit,
        };
        var o = (0, __1.deepMerge)(currentOptions, to);
        this.options = __assign(__assign({}, this.options), o);
        this.meta = (0, __1.deepMerge)(this.meta, to.meta);
        this.optionListeners.forEach(function (l) { return l(_this.options, _this.meta); });
    };
    return QueryManager;
}());
exports.QueryManager = QueryManager;
