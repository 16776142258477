import { React, AppForms, DefaultDateFormat, Settings, navigation } from '@/config'
import { APIClient } from '@/services'
import { useForm, useI18N, useState } from '@codeleap/common'
import { Button, Checkbox, Text, TextInput, View, AuthFormComponents, OnboardingStore } from '@/components'
import { DatePicker, useKeydown } from '@codeleap/web'
import { AppStatus } from '@/redux'
import { format } from 'date-fns'

type SignupFormProps = {
  title?: string
}

export const SignupForm = ({ title = null }: SignupFormProps) => {
  const [signUpSource, setSignUpSource] = useState('email')

  const form = useForm(signUpSource === 'email' ? AppForms.signup : AppForms.socialSignup)

  const handleSocial = APIClient.Session.useSocialLogin((userSocial, provider) => {
    if (userSocial) {
      AppStatus.set('loading')
      form.setFormValues(userSocial)
      setSignUpSource(provider)
      handleSubmit(true, { ...userSocial, birthday: form.values?.birthday })
    }
  }, 'signup')

  const { t } = useI18N()

  const { signup } = APIClient.Session.useSignup()

  const isSocial = signUpSource !== 'email'

  const handleSubmit = async (isSocialSignup = false, userValues = null) => {
    const values = (userValues ?? form?.values)

    const data = {
      ...values,
      birthday: format(values.birthday, DefaultDateFormat),
    }

    const result = await signup(data, signUpSource as any)

    if (!!result?.user) {
      if (isSocialSignup || !Settings.AppConfig.emailVerificationEnabled) {
        navigation.navigate('Home')
        OnboardingStore.getState().show()
        return
      }

      navigation.navigate('Auth.VerifyEmail')
    }
  }

  useKeydown('Enter', () => {
    const isInvalidSubmit = !form.isValid
    if (isInvalidSubmit) return
    handleSubmit?.()
  }, [form?.values])

  return (
    <View style={['column', 'fullWidth']}>
      <Text
        style={['h1', 'extraBold', 'marginBottom:4']}
        text={title || t('SignUp.title')}
      />
      <Text style={['p1', 'marginBottom:4', 'marginTop:-3']} text={t('SignUp.subtitle') + ' '} />
      <View style={['flex', 'column', 'gap:4', 'fullWidth']}>
        <View style={['column', 'gap:1']}>
          <TextInput {...form.register('first_name')} />
          <TextInput {...form.register('last_name')} />
          <TextInput
            {...form.register('email')}
            leftIcon={{ icon: 'mail' }}
            disabled={isSocial}
          />
          <DatePicker {...form.register('birthday')} />
          {
            isSocial ? null : (
              <>
                <TextInput
                  {...form.register('password')}
                  leftIcon={{ name: 'key' }}
                  visibilityToggle
                />
                <TextInput
                  {...form.register('repeatPassword')}
                  leftIcon={{ name: 'key' }}
                  visibilityToggle
                />
              </>
            )
          }
          <View style={['column', 'gap:2']}>
            <View>
              <Checkbox
                {...form.register('terms')}
                style={['left']}
              />
            </View>
          </View>
        </View>

        <AuthFormComponents.SocialProviders onPress={handleSocial} />

        <View style={['column', 'gap:2']}>
          <Button
            onPress={handleSubmit}
            disabled={!form.isValid}
            debugName={`submit Signup`}
            text={t('SignUp.submit')}
            style={['marginHorizontal:auto', 'fullWidth', 'large', 'paddingHorizontal:2', !form.isValid && 'outline']}
          />

          <AuthFormComponents.RedirectLink
            route={'Auth.Login'}
            text={`${t('SignUp.txt1')}`}
            textAction={`${t('actions.login')}`}
          />
        </View>
      </View>
    </View>
  )
}
