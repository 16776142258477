import React from 'react'
import { AnyFunction, useBooleanToggle, useI18N } from '@codeleap/common'
import { View, Button, Modal } from '@/components'

import { createStyles } from '@codeleap/styles'
import { customTextStyles } from '@/config'
import { I18NDictionary } from '@/config'

const languageOptions = Object.entries(I18NDictionary).map(([key, value]) => ({
  label: value.__LABEL,
  value: key,
}))

type LanguageSelectorProps = {
  onPress?: AnyFunction
}



export const LanguageSelector = ({ onPress }: LanguageSelectorProps) => {
  const [visible, toggle] = useBooleanToggle(false)

  const { locale, t, setLocale } = useI18N()
  // const { originalPath } = useI18next()

  return <>
    <View style={['column', 'gap:2']}>
      <Button
        text={t('languageSelector', { language: locale })}
        onPress={() => {
          onPress?.()
          toggle()
        }}
        debugName='open language selector modal'
        style={['outline']}
        rightIcon='languages'
      />
    </View>

    <Modal
      toggle={toggle}
      debugName='Language selector'
      style={['centered', 'box:responsiveness']}
      visible={visible}
      title='Language'
      description='Select a language'
    >
      <View style={['gap:1', 'column']}>
        {languageOptions.map((item) => {
          return (
            <Button
              // component={_Link as unknown as 'button'}
              debugName='language selector'
              key={item.label}
              
              style={['link', (item.value === locale) && 'link:outline']}
              language={item.value}
              onClick={() => {
                toggle(false)
                setLocale(item?.value)
              }}
            >
              {item.label}
            </Button>
          )
        })}
      </View>
    </Modal>
  </>
}

const styles = createStyles(theme => ({
  link: {
    textDecoration: 'none',
    ...customTextStyles('p1'),
    color: theme.colors.neutral10,
  }
}))
