import React, { ElementType } from 'react'
import { Touchable, View } from '@/components'
import { TypeGuards } from '@codeleap/common'
import { useStylesFor, ViewProps, TouchableProps } from '@codeleap/web'
import { StyleSheets, StyleRegistry } from '@/config'
import { StyledComponent, StyledProp } from '@codeleap/styles'

export type CardBaseProps =
  Omit<ViewProps, 'style'> &
  Omit<TouchableProps, 'style' | 'debugName'> &
  {
    style?: StyledProp<StyleSheets.CardBaseComposition>
    debugName?: string
    children?: React.ReactNode
    innerWrapperProps?: ViewProps
    gap?: number
    WrapperComponent?: React.ReactElement
    InnerWrapperComponent?: React.ReactElement
  }

export const CardBase: StyledComponent<typeof StyleSheets.CardBaseStyles, CardBaseProps> = (props) => {
  const {
    children,
    style,
    onPress,
    WrapperComponent,
    InnerWrapperComponent,
    innerWrapperProps,
    gap,
    ...rest
  } = props

  const styles = useStylesFor(CardBase.styleRegistryName, style)

  const isPressable = TypeGuards.isFunction(onPress)

  const wrapperStyles = [
    styles.wrapper,
    isPressable ? styles['wrapper:pressable'] : null,
  ]

  const innerWrapperStyles = [
    !!gap ? `gap:${gap}` : null,
    styles.innerWrapper,
    isPressable ? styles['innerWrapper:pressable'] : null,
  ]

  const Wrapper: ElementType = (WrapperComponent || (isPressable ? Touchable : View)) as unknown as ElementType
  const InnerWrapper: ElementType = (InnerWrapperComponent || View) as unknown as ElementType

  return (
    <Wrapper
      debugName={'Card Base Wrapper'}
      onPress={onPress}
      {...rest}
      style={wrapperStyles}
    >
      <InnerWrapper
        debugName={'Card Base Inner Wrapper'}
        {...innerWrapperProps}
        style={innerWrapperStyles}
      >
        {children}
      </InnerWrapper>
    </Wrapper>
  )
}

CardBase.styleRegistryName = 'CardBase'
CardBase.elements = ['wrapper', 'innerWrapper']

StyleRegistry.registerComponent(CardBase)
