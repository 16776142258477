import { StylesOf } from '@codeleap/common'
import { ButtonComposition, ButtonProps, useStylesFor } from '@codeleap/web'
import { Button, Link } from '@/components'
import { navigation, StyleSheets } from '@/config'
import { AnyRecord, PropsWithVariants, useNestedStylesByKey, concatStyles } from '@codeleap/styles'
import { NavigationComponent, useNavigation } from '..'

export type NavigationButtonProps<T extends AppRoute> =
  Partial<Omit<ButtonProps, 'style'>> &
  {
    to: T
    routeParams?: Record<RouteParams<T>, string | number>
    exactRoute?: boolean
    style?: PropsWithVariants<AnyRecord, typeof StyleSheets.NavigationStyles>['style']
  }

export const NavigationButton = <T extends AppRoute>(props: NavigationButtonProps<T>) => {
  const {
    to,
    routeParams,
    style,
    disabled,
    exactRoute = false,
    ...rest
  } = props

  const { style: containerStyle } = useNavigation()

  const styles = useStylesFor(NavigationComponent.styleRegistryName, concatStyles<StyleSheets.NavigationComposition>([containerStyle, style]))

  const isFocused = navigation.isCurrentRoute(to as T, routeParams, exactRoute)

  const itemStyles: StylesOf<ButtonComposition> = useNestedStylesByKey('item', styles)

  const buttonStyle: StylesOf<ButtonComposition> = {
    ...itemStyles,
    wrapper: {
      ...itemStyles?.wrapper,
      ...(isFocused ? itemStyles?.['wrapper:focused'] : {}),
    },
    text: {
      ...itemStyles?.text,
      ...(isFocused ? itemStyles?.['text:focused'] : {}),
    },
    icon: {
      ...itemStyles?.icon,
      ...(isFocused ? itemStyles?.['icon:focused'] : {}),
    },
  }

  return (
    <Button
      component={disabled ? null : Link as unknown as 'button'}
      {...rest}
      disabled={disabled}
      // @ts-expect-error
      route={to}
      routeParams={routeParams}
      style={buttonStyle}
    />
  )
}
